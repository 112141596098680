import React, { useContext, useEffect, useState } from "react"
import { Link } from "@chakra-ui/react"
import { Box, HStack, Flex, Button, Spacer, Heading } from "@chakra-ui/react"
import { Auth } from "../contexts/Auth"
import { logout } from "../services/AuthApi"
import { getItem } from "../services/LocalStorage"
import jwt_decode from "jwt-decode"
import { Navigate } from "react-router-dom"
import { Payload } from "./utils"

export default function Header() {
  const { isAuthentificated, setIsAuthentificated } = useContext(Auth)
  const [isAdmin, setIsAdmin] = useState(false)
  const [name, setName] = useState("")
  const handleLogout = () => {
    logout()
    setIsAuthentificated(false)
    Navigate({ to: "/login" })
  }
  useEffect(() => {
    const token = getItem("token")
    if (token === null) {
      return
    }
    //payload jwt token
    const payload: Payload = jwt_decode(token)
    setIsAdmin(payload.isAdmin)
    setName(payload.name)
  }, [isAuthentificated])
  return (
    <Flex
      w="100%"
      h="60px"
      align="center"
      borderBottom="solid 1px"
      borderColor="#D6DCE0"
    >
      <Heading
        ml="20px"
        size="md"
        color="c2i.600"
        fontWeight={700}
        fontSize="2rem"
      >
        C2i Impact
      </Heading>
      <Spacer />
      <Box mr="30px">
        {isAuthentificated && (
          <HStack spacing="20px">
            <Box>{name}</Box>
            <Button colorScheme="c2i" onClick={handleLogout}>
              Se déconnecter
            </Button>
          </HStack>
        )}
      </Box>
    </Flex>
  )
}
