import {Box, Center, ListItem, UnorderedList} from "@chakra-ui/react"
import {FournisseursType} from "components/Admin/Fournisseurs"
import {EmballageWithBenchmark} from "./utils"
import {getRecycledWeightPartFormElements} from "./utils_scoring"

interface IProps {
    listFournisseurs: Array<FournisseursType> | undefined
    emballageWithBenchmark: EmballageWithBenchmark | undefined
    emballageOrBenchmark: "emballage" | "benchmark"
    emballageName: string
}

export default function ScoringDetailDescription({
                                                     listFournisseurs,
                                                     emballageWithBenchmark,
                                                     emballageOrBenchmark,
                                                     emballageName,
                                                 }: IProps) {
    const bouchonRecycledRatio = getRecycledWeightPartFormElements(emballageWithBenchmark?.[emballageOrBenchmark]?.bouchon.elements)
    const teteRecycledRatio = getRecycledWeightPartFormElements(emballageWithBenchmark?.[emballageOrBenchmark]?.tete.elements)
    const corpsRecycledRatio = getRecycledWeightPartFormElements(emballageWithBenchmark?.[emballageOrBenchmark]?.corps.elements)
    return (
        <Box w="400px" bg="c2i.100" borderRadius={10} m="10px" p="10px">
            <Center fontSize={18} textTransform="uppercase" fontWeight={800}>
                {emballageName}
            </Center>
            <Box
                mt="10px"
                fontSize={15}
            >{`Contenance: ${emballageWithBenchmark?.[emballageOrBenchmark].contenance} ml`}</Box>

            <Box
                fontSize={15}
            >{`Type: ${emballageWithBenchmark?.[emballageOrBenchmark].type}`}</Box>
            <Box mt="10px" fontWeight={700}>
                Compositions
            </Box>
            {emballageWithBenchmark?.[emballageOrBenchmark]?.bouchon
                .fournisseur_id && (
                <Box pl="5px">
                    <Box fontWeight={500} mt="7px">
                        Fermeture: bouchon / pompe {recycleTitle(bouchonRecycledRatio)}</Box>
                    {fournisseurPays(listFournisseurs, emballageWithBenchmark?.[emballageOrBenchmark]?.bouchon)}

                    <UnorderedList>
                        {emballageWithBenchmark?.[
                            emballageOrBenchmark
                            ]?.bouchon?.elements?.map((e, index) => {
                            return (
                                <ListItem fontSize={15} key={index} ml="15px">
                                    {`${e.sous_materiau}`}
                                </ListItem>
                            )
                        })}
                    </UnorderedList>
                </Box>
            )}
            {emballageWithBenchmark?.[emballageOrBenchmark]?.tete.fournisseur_id && (
                <Box pl="5px">
                    <Box fontWeight={500} mt="7px">
                        Tête: cuvette / opercule {recycleTitle(teteRecycledRatio)}</Box>
                    {fournisseurPays(listFournisseurs, emballageWithBenchmark?.[emballageOrBenchmark]?.tete)}
                    <UnorderedList>
                        {emballageWithBenchmark?.[
                            emballageOrBenchmark
                            ]?.tete?.elements?.map((e, index) => {

                            return (
                                <ListItem fontSize={15} key={index} ml="15px">
                                    {`${e.sous_materiau}`}
                                </ListItem>
                            )
                        })}
                    </UnorderedList>
                </Box>
            )}
            {emballageWithBenchmark?.[emballageOrBenchmark]?.corps && (
                <Box pl="5px">
                    <Box fontWeight={500} mt="7px">Corps {recycleTitle(corpsRecycledRatio)}</Box>
                    {fournisseurPays(listFournisseurs, emballageWithBenchmark?.[emballageOrBenchmark]?.corps)}
                    <UnorderedList>
                        {emballageWithBenchmark?.[
                            emballageOrBenchmark
                            ]?.corps?.elements?.map((e, index) => {

                            return (
                                <ListItem fontSize={15} key={index} ml="15px">
                                    {`${e.sous_materiau}`}
                                </ListItem>
                            )
                        })}
                    </UnorderedList>
                </Box>
            )}
            <Box mt="10px" fontWeight={700}>
                Transport
            </Box>
            {emballageWithBenchmark?.[emballageOrBenchmark]?.transports?.map(
                (e, index) => (
                    <UnorderedList key={index}>
                        <Box fontSize={15}>{`Parts : ${e.parts} : `}</Box>
                        <Box pl="15px">
                            {e.segments.map((item, index1) => (
                                <ListItem
                                    key={index1}
                                    fontSize={15}
                                >{`${item.mode}, distance: ${item.distance} km, type: ${item.type}`}</ListItem>
                            ))}
                        </Box>
                    </UnorderedList>
                )
            )}
        </Box>
    )
}

const fournisseurPays = (listFournisseurs: any, composant: any) => {
    return (
        <Box fontWeight={400} mt="3px" ml="15px">
            {
                listFournisseurs?.filter(
                    (f: any) =>
                        f._id ===
                        composant.fournisseur_id
                )[0].name
            }, pays: {
            listFournisseurs?.filter(
                (f: any) =>
                    f._id ===
                    composant
                        .fournisseur_id
            )[0].pays
        }
        </Box>)
}

const recycleTitle = (recycledPart: string | undefined) => {
    if (recycledPart !== undefined) {
        return `[${recycledPart}% recyclé]`
    }
    return ""
}
