import { Auth } from "../contexts/Auth"
import React, { useContext, useEffect, useState } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { getItem } from "../services/LocalStorage"
import jwt_decode from "jwt-decode"
import { Payload } from "./utils"

const AuthenticatedRouteAdmin = () => {
  const { isAuthentificated } = useContext(Auth)
  const [isAdmin, setIsAdmin] = useState<Boolean | undefined>(undefined)

  useEffect(() => {
    const token = getItem("token")
    if (token === null) {
      return
    }
    //payload jwt token
    const payload: Payload = jwt_decode(token)
    setIsAdmin(payload.isAdmin)
  }, [isAdmin])
  //return isAdmin && isAuthentificated ? <Outlet /> : <Navigate to="/login" />
  return (
    <>
      {!isAuthentificated && <Navigate to="/login" />}
      {isAuthentificated && isAdmin === undefined && <></>}
      {isAuthentificated && isAdmin === true && <Outlet />}
      {isAuthentificated && isAdmin === false && <Navigate to="/" />}
    </>
  )
}

export default AuthenticatedRouteAdmin
