import { getItem, removeItem } from "./LocalStorage"
import jwtDecode from "jwt-decode"
import { config } from "../Constants"
import {Credentials, Payload} from "../components/utils"
import jwt_decode from "jwt-decode";
import moment from "moment";
export function hasAuthentificated() {
  const token = getItem("token")
  const tokenIsValid = token ? tokenIsValidFct(token) : false
  if (tokenIsValid === false) {
    removeItem("token")
  }
  return tokenIsValid
}

export function login(credentials: Credentials) {
  const postLogin = async (credentials: Credentials) => {
    const url = config.url.API_URL
    const response = await fetch(url + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
    const body = await response.json()
    if (response.status === 401) {
      return
    }
    return body
  }

  return postLogin(credentials)
}

export function logout() {
  removeItem("token")
}

function tokenIsValidFct(token: string) {
  const payload: Payload = jwt_decode(token)
  if (payload.expiry_date === undefined || payload.user_id === undefined) {
    // old token -> need refresh
    return false
  }
  const expiryDate = moment.utc(payload.expiry_date)
  const nowDate = moment.utc()
  return nowDate.isBefore(expiryDate)

}
