import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"

import axios from "axios"
import { useEffect, useState } from "react"
import { getItem } from "services/LocalStorage"
import NumberFormat from "react-number-format"
import { config } from "Constants"
import { EditIcon, DeleteIcon, CopyIcon } from "@chakra-ui/icons"
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react"
import { Spinner } from "@chakra-ui/react"

import { get_carbon_footer_print, get_eco_circularite } from "./utils_scoring"
import { useNavigate } from "react-router-dom"
import AlertScoring from "./AlertScoring"
import { EmballageWithBenchmark } from "./utils"
import ScoringModal from "./ScoringModal"
import { Client } from "components/utils"
import { FournisseursType } from "components/Admin/Fournisseurs"
import moment, { Moment } from "moment"
import { Link } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"

export default function Emballages() {
  const [emballages, setEmballages] = useState<
    Array<EmballageWithBenchmark> | undefined
  >(undefined)
  const [emballagesFiltered, setEmballagesFiltered] = useState<
    Array<EmballageWithBenchmark> | undefined
  >(undefined)
  const [needToCalculate, SetNeedToCalculate] = useState<
    Array<Boolean> | undefined
  >(undefined)
  const [emballageWithBenchmark, setEmballageWithBenchmark] = useState<
    EmballageWithBenchmark | undefined
  >(undefined)
  const url = config.url.API_URL
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isDetailOpen,
    onOpen: onDetailOpen,
    onClose: onDetailClose,
  } = useDisclosure()
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure()
  const [emballageId, setEmballageId] = useState<any>(undefined)
  const [emballageIdToDelete, setEmballageIdToDelete] = useState<any>(undefined)
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  )
  const [emballagesLoaded, setEmballagesLoaded] = useState(false)
  const [clients, setClients] = useState<Array<Client> | undefined>(undefined)
  const [listFournisseurs, setListFournisseurs] = useState<
    Array<FournisseursType> | undefined
  >(undefined)
  const [dateIn, setDateIn] = useState<Moment | undefined>(undefined)
  const [dateOut, setDateOut] = useState<Moment | undefined>(undefined)
  const [scoreAll, setScoreAll] = useState<number | undefined>(undefined)
  const [ecoScoreAll, setEcoScoreAll] = useState<number | undefined>(undefined)
  const [scoreAllBenchmark, setScoreAllBenchmark] = useState<
    number | undefined
  >(undefined)
  const [ecoScoreAllBenchmark, setEcoScoreAllBenchmark] = useState<
    number | undefined
  >(undefined)
  const token = getItem("token")
  useEffect(() => {
    // Get all emballages

    axios
      .get(`${url}/emballages/all_emballages/${token}`)
      .then((res) => {
        const sorted = res.data.sort((a: any, b: any) => {
          return moment(b.updated_date).unix() - moment(a.updated_date).unix()
        })
        setEmballages(sorted)
        setEmballagesLoaded(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (emballages !== undefined) {
      const url = config.url.API_URL
      axios
        .get(url + "/clients/" + token)
        .then((res) => {
          setClients(res.data)
        })
        .catch((res) => {
          console.log(res)
          //setRefs(res.data)
        })
    }
  }, [emballages])

  useEffect(() => {
    const url = config.url.API_URL
    axios
      .get(url + "/fournisseurs")
      .then((res) => {
        setListFournisseurs(res.data)
      })
      .catch((res) => {
        console.log(res)
        //setRefs(res.data)
      })
  }, [])

  useEffect(() => {
    // Analyse updated date and scoring date
    if (!listFournisseurs) {
      return
    }
    if (emballages !== undefined) {
      SetNeedToCalculate(
        emballages.map((emballage) => {
          if (emballage.scoring === null || emballage.scoring === undefined) {
            return true
          }
          const updatedDate = new Date(emballage.updated_date)
          const scoringDate = new Date(emballage.scoring.scoring_date)
          // Check if fournisseur has been modified after scoring
          const fournisseurIdList = [
            ...new Set(
              [
                emballage.emballage.bouchon.fournisseur_id,
                emballage.emballage.tete.fournisseur_id,
                emballage.emballage.corps.fournisseur_id,
                emballage.benchmark.bouchon.fournisseur_id,
                emballage.benchmark.tete.fournisseur_id,
                emballage.benchmark.corps.fournisseur_id,
              ].filter((fournisseurId) => fournisseurId !== "")
            ),
          ]

          if (
            listFournisseurs
              .filter((fournisseur) =>
                fournisseurIdList.includes(fournisseur._id)
              )
              .map((fournisseur) => new Date(fournisseur.updated_date))
              .map((fournisseur_date) => scoringDate < fournisseur_date)
              .some((v) => v === true)
          ) {
            return true
          }

          if (updatedDate > scoringDate) {
            return true
          }
          return false
        })
      )
    }
  }, [emballages, listFournisseurs])

  const calculateScoring = (emballage_id: string) => {
    setEmballageId(emballage_id)
    onOpen()
  }

  const duplicate = (emballage_id: string) => {
    axios
      .post(`${url}/emballages/duplicate/${emballage_id}`)
      .then((res) => {})
      .then(() => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const remove = (emballage_id: string) => {
    axios
      .delete(`${url}/emballages/${emballage_id}`)
      .then((res) => {})
      .then(() => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const displayDetails = (emballage_id: string) => {
    setEmballageWithBenchmark(emballages?.find((e) => e._id === emballage_id))
    onDetailOpen()
  }
  const convertDate = (old_date: string) => {
    const timestamp = Date.parse(old_date)
    const date = new Date(timestamp)
    return date.toLocaleDateString("fr")
  }
  useEffect(() => {
    if (!emballages) {
      return
    }
    setEmballagesFiltered(emballages)
  }, [emballages])

  const ApplyFilters = () => {
    setEmballagesFiltered(
      emballages
        ?.filter((e) =>
          dateIn?.isValid() ? e.updated_date >= dateIn.toISOString() : true
        )
        .filter((e) =>
          dateOut?.isValid() ? e.updated_date <= dateOut.toISOString() : true
        )
        .filter((e) => (selectedClient ? e.client_id === selectedClient : true))
    )
  }

  // Calcul des scoring total
  useEffect(() => {
    if (emballagesFiltered) {
      setScoreAll(
        emballagesFiltered
          .map(
            (f) =>
              (get_carbon_footer_print(f.scoring, false) *
                parseInt(f.quantity)) /
              1000
          )
          .reduce((partialSum, a) => partialSum + a, 0)
      )
      setScoreAllBenchmark(
        emballagesFiltered
          .map(
            (f) =>
              (get_carbon_footer_print(f.scoring, true) *
                parseInt(f.quantity)) /
              1000
          )
          .reduce((partialSum, a) => partialSum + a, 0)
      )
      setEcoScoreAll(
        scoreAll
          ? emballagesFiltered
              .map(
                (f) =>
                  ((get_carbon_footer_print(f.scoring, false) *
                    parseInt(f.quantity)) /
                    1000) *
                  get_eco_circularite(f.scoring, false)
              )

              .reduce((partialSum, a) => partialSum + a, 0) / scoreAll
          : 0
      )
      setEcoScoreAllBenchmark(
        scoreAllBenchmark
          ? emballagesFiltered
              .map(
                (f) =>
                  ((get_carbon_footer_print(f.scoring, true) *
                    parseInt(f.quantity)) /
                    1000) *
                  get_eco_circularite(f.scoring, true)
              )
              .reduce((partialSum, a) => partialSum + a, 0) / scoreAllBenchmark
          : 0
      )
    }
  }, [emballagesFiltered, scoreAll, scoreAllBenchmark])

  return (
    <>
      <ScoringModal
        emballageWithBenchmark={emballageWithBenchmark}
        isDetailOpen={isDetailOpen}
        onDetailOpen={onDetailOpen}
        onDetailClose={onDetailClose}
        clients={clients}
        listFournisseurs={listFournisseurs}
      />
      {!emballagesLoaded && (
        <Box m="50px">
          <Spinner />
          Chargement en cours ...
        </Box>
      )}
      <AlertScoring
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        emballageId={emballageId}
      />
      <VStack pl="15px">
        {emballagesFiltered && emballages && needToCalculate && (
          <VStack align="left">
            <HStack>
              <Button
                w="150px"
                m="20px"
                colorScheme="c2i"
                size="sm"
                onClick={(x) => {
                  navigate("/emballages/add")
                }}
              >
                Nouvel Emballage
              </Button>
              <Link
                bg="c2i.500"
                color="white"
                borderRadius={5}
                padding="5px 10px"
                href="https://drive.google.com/file/d/1Ub6YHAk_jtJdqJWUQ0fp-EglWbzJYmQI/view?usp=sharing"
                isExternal
              >
                Documentation <ExternalLinkIcon mx="2px" />
              </Link>
            </HStack>
            <Box bg="#F4F4F4" borderRadius={5}>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td borderBottom={0}>Empreinte Carbone des emballages</Td>
                    <Td borderBottom={0}>
                      <NumberFormat
                        value={scoreAll}
                        displayType="text"
                        decimalScale={2}
                        suffix=" kgCO2eq"
                        type="text"
                        thousandSeparator={false}
                        allowNegative={true}
                      />
                    </Td>
                    <Td borderBottom={0}>Empreinte Carbone des benchmarks</Td>
                    <Td borderBottom={0}>
                      <NumberFormat
                        value={scoreAllBenchmark}
                        displayType="text"
                        decimalScale={2}
                        suffix=" kgCO2eq"
                        type="text"
                        thousandSeparator={false}
                        allowNegative={true}
                      />
                    </Td>
                  </Tr>

                  <Tr>
                    <Td borderBottom={0}>Eco-circularité des emballages</Td>
                    <Td borderBottom={0}>
                      <NumberFormat
                        value={ecoScoreAll ? ecoScoreAll * 100 : 0}
                        displayType="text"
                        decimalScale={2}
                        suffix=" %"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={true}
                      />
                    </Td>
                    <Td borderBottom={0}>Eco-circularité des benchmarks</Td>
                    <Td borderBottom={0}>
                      <NumberFormat
                        value={
                          ecoScoreAllBenchmark ? ecoScoreAllBenchmark * 100 : 0
                        }
                        displayType="text"
                        decimalScale={2}
                        suffix=" %"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={true}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>

            <HStack spacing={2} pt="15px">
              <Select
                w="150px"
                size="sm"
                placeholder="Filtrer par Client"
                onChange={(e) => setSelectedClient(e.target.value)}
                value={selectedClient}
              >
                {[
                  ...new Set(
                    emballages.map((emballage) => emballage.client_id)
                  ),
                ].map((clientId, index) => (
                  <option key={index} value={clientId}>
                    {clients
                      ? clients.filter((c) => c.id === clientId)[0].name
                      : null}
                  </option>
                ))}
              </Select>
              <Input
                w="150px"
                size="sm"
                placeholder="date de début"
                //value={dateIn?.toString()}
                onChange={(e) =>
                  setDateIn(moment(e.target.value, "DD/MM/YYYY"))
                }
              ></Input>
              <Input
                w="150px"
                size="sm"
                placeholder="date de fin"
                //value={dateOut?.toString()}
                onChange={(e) =>
                  setDateOut(moment(e.target.value, "DD/MM/YYYY"))
                }
              ></Input>
              <Button size="sm" onClick={ApplyFilters} colorScheme="c2i">
                Appliquer
              </Button>
            </HStack>
            <TableContainer m="50px" minW="500px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Scoring</Th>
                    <Th>Date</Th>
                    <Th>Client</Th>
                    <Th>Libellé</Th>
                    <Th>Quantité</Th>
                    <Th>
                      <Box>Score</Box>
                      <Box textTransform="none">kgCO2eq</Box>
                    </Th>
                    <Th>Eco-circularité</Th>
                    <Th>
                      <Box>Score</Box>
                      <Box>Benchmark</Box>
                    </Th>
                    <Th>
                      <Box>Eco-circularité</Box>
                      <Box>Benchmark</Box>
                    </Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {emballagesFiltered.map(
                    (emballage: EmballageWithBenchmark, index: any) => {
                      const carbon_footprint_emballage =
                        get_carbon_footer_print(emballage.scoring, false)
                      const eco_circularite_emballage = get_eco_circularite(
                        emballage.scoring,
                        false
                      )
                      const carbon_footprint_benchmark =
                        get_carbon_footer_print(emballage.scoring, true)
                      const eco_circularite_benchmark = get_eco_circularite(
                        emballage.scoring,
                        true
                      )
                      return (
                        <Tr key={index}>
                          <Td>
                            {needToCalculate && needToCalculate[index] && (
                              <Button
                                colorScheme="red"
                                size="sm"
                                onClick={() => calculateScoring(emballage._id)}
                              >
                                Calculer
                              </Button>
                            )}
                            {needToCalculate && !needToCalculate[index] && (
                              <Button
                                colorScheme="c2i"
                                size="sm"
                                onClick={() => displayDetails(emballage._id)}
                              >
                                Détails
                              </Button>
                            )}
                          </Td>
                          <Td>{convertDate(emballage.updated_date)}</Td>
                          <Td>
                            {clients
                              ? clients.filter(
                                  (c) => c.id === emballage.client_id
                                )[0].name
                              : null}
                          </Td>
                          <Td>{emballage.name}</Td>
                          <Td isNumeric={true}>{emballage.quantity}</Td>
                          <Td isNumeric={true}>
                            <NumberFormat
                              value={
                                (carbon_footprint_emballage *
                                  parseInt(emballage.quantity)) /
                                1000
                              }
                              displayType="text"
                              decimalScale={2}
                              //suffix=" kgCO2eq"
                              type="text"
                              thousandSeparator={false}
                              allowNegative={true}
                            />
                          </Td>
                          <Td isNumeric={true}>
                            <NumberFormat
                              value={eco_circularite_emballage * 100}
                              displayType="text"
                              decimalScale={2}
                              suffix=" %"
                              type="text"
                              thousandSeparator={false}
                              allowNegative={true}
                            />
                          </Td>
                          <Td isNumeric={true}>
                            <NumberFormat
                              value={
                                (carbon_footprint_benchmark *
                                  parseInt(emballage.quantity)) /
                                1000
                              }
                              displayType="text"
                              decimalScale={2}
                              //suffix=" kgCO2eq"
                              type="text"
                              thousandSeparator={false}
                              allowNegative={true}
                            />
                          </Td>
                          <Td isNumeric={true}>
                            <NumberFormat
                              value={eco_circularite_benchmark * 100}
                              displayType="text"
                              decimalScale={2}
                              suffix=" %"
                              type="text"
                              thousandSeparator={false}
                              allowNegative={true}
                            />
                          </Td>
                          <Td>
                            <HStack>
                              <Tooltip label="Modifier l'emballage">
                                <IconButton
                                  onClick={(x) => {
                                    navigate(
                                      "/emballages/edit/" + emballage._id
                                    )
                                  }}
                                  icon={<EditIcon />}
                                  aria-label="edit packaging"
                                />
                              </Tooltip>
                              <Tooltip label="Dupliquer l'emballage">
                                <IconButton
                                  onClick={() => duplicate(emballage._id)}
                                  icon={<CopyIcon />}
                                  aria-label="copy packaging"
                                />
                              </Tooltip>
                              <Tooltip label="Supprimer l'emballage">
                                <IconButton
                                  onClick={() => {
                                    setEmballageIdToDelete(emballage._id)
                                    onDeleteOpen()
                                  }}
                                  icon={<DeleteIcon />}
                                  aria-label="delete packaging"
                                />
                              </Tooltip>
                              <Modal
                                isOpen={isDeleteOpen}
                                onClose={onDeleteClose}
                              >
                                <ModalOverlay />
                                <ModalContent>
                                  <ModalHeader>
                                    Suppression emballage
                                  </ModalHeader>
                                  <ModalCloseButton />
                                  <ModalBody>
                                    <HStack spacing="15px">
                                      <Button
                                        onClick={() =>
                                          remove(emballageIdToDelete)
                                        }
                                        colorScheme="red"
                                      >
                                        Confirmer
                                      </Button>
                                      <Button
                                        onClick={onDeleteClose}
                                        colorScheme="c2i"
                                      >
                                        Annuler
                                      </Button>
                                    </HStack>
                                  </ModalBody>
                                </ModalContent>
                              </Modal>
                            </HStack>
                          </Td>
                        </Tr>
                      )
                    }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        )}
      </VStack>
    </>
  )
}

//new function
