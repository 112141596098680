import {useState} from "react";
import {config} from "../../Constants";
import axios from "axios";
import {Box, Button, VStack} from "@chakra-ui/react";
import {getItem} from "../../services/LocalStorage";

interface NewUser {
    login: string;
    password: string;
}


export default function NewDemoAccount() {
    const [new_user_info, setNewUserInfo] = useState<NewUser | undefined>(undefined)
    const [new_user_info_is_loading, setNewUserInfoIsLoading] = useState<boolean>(false)


    const create_new_account = () => {
        setNewUserInfoIsLoading(true)
        const url = config.url.API_URL
        axios
            .post(url + "/demo_account/new")
            .then((res) => {
                setNewUserInfo(res.data)
                console.log(res.data)
            })
            .catch((res) => {
                console.log(res)
                //setRefs(res.data)
            })
            .finally(() => {
                setNewUserInfoIsLoading(false)
            })
    }

        return <><Button onClick={create_new_account} colorScheme="c2i">Créer un compte de démonstration</Button>
            {new_user_info_is_loading && <Box mt="30px">Chargement...</Box>}
            {new_user_info &&
                <VStack mt="30px">
                    <p>Identifiant : {new_user_info.login} </p>
                    <p>Mot de passe : {new_user_info.password}</p>
                </VStack>}
        </>
    }