import { useState } from "react"
import {
  Box,
  HStack,
  Button,
  Input,
  VStack,
  Checkbox,
  Select,
  useDisclosure,
} from "@chakra-ui/react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react"
import { S, Segment } from "./utils"

interface IProps {
  values: any
  setValues: any
  option_transport: any
  base: string
}

export default function NewTransport({
  setValues,
  values,
  option_transport,
  base,
}: IProps) {
  //const path = "/emballages/list"
  const [fournisseur, setFournisseur] = useState<string>("")
  const [conditionneur, setConditionneur] = useState<string>("")
  const [parts, setParts] = useState<string[]>([])
  const [segments, setSegments] = useState<Array<Segment>>([])
  const [isNewTransport, setIsNewTransport] = useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleChangeFournisseur = (event: any) =>
    setFournisseur(event.target.value)
  const handleChangeConditionneur = (event: any) =>
    setConditionneur(event.target.value)

  const addTransport = () => {
    setSegments((prev) => [...prev, { type: "", mode: "", distance: "" }])
  }

  const resetTransport = () => {
    setSegments([])
    setParts([])
  }
  const handleChange = (value: string, index: number, name: S) => {
    setSegments((prev) => {
      const new_segments = [...prev]
      new_segments[index][name] = value
      return new_segments
    })
  }
  const saveProcess = () => {
    //Validation des données:
    for (const segment of segments) {
      if (isNaN(Number(segment.distance)) || segment.distance === "") {
        alert("Distance invalide")
        return
      }
    }
    for (const segment of segments) {
      if (segment.mode === "") {
        alert("Veuillez saisir un mode de transport")
        return
      }
    }
    for (const segment of segments) {
      if (segment.mode !== "Avion" && segment.type === "") {
        alert("Veuillez saisir un type de transport")
        return
      }
    }
    saveTransport()
    onClose()
  }
  const saveTransport = () => {
    setValues((prev: any) => {
      const new_transport = {
        fournisseur_id: fournisseur,
        conditionneur: conditionneur,
        parts: parts,
        segments: segments,
      }
      const new_prev = { ...prev }
      console.log(new_prev)
      new_prev[base].transports = new_prev[base].transports
        ? [...new_prev[base].transports, new_transport]
        : [new_transport]
      return new_prev
    })
    resetTransport()
  }
  const updateParts = (e: any, partsName: string) => {
    setParts((prev) => {
      const new_parts = [...prev]
      if (e.target.checked) {
        new_parts.push(partsName)
      } else {
        new_parts.splice(new_parts.indexOf(partsName), 1)
      }
      return new_parts
    })
  }
  const transportType = ["Camion", "Train", "Bateau", "Avion"]
  return (
    <>
      {/* <Input
        bg="white"
        size="xs"
        placeholder="fournisseur"
        value={fournisseur}
        onChange={handleChangeFournisseur}
      />
      <Input
        bg="white"
        size="xs"
        value={conditionneur}
        placeholder="conditionneur"
        onChange={handleChangeConditionneur}
      /> */}

      <Button onClick={onOpen} colorScheme="c2i" size="sm">
        Ajouter un transport
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajout d'un transport</ModalHeader>

          <ModalBody>
            <Box m="15px">
              1 - Sélectionnez le ou les parties concernées par le transport
            </Box>
            <VStack align="left">
              <Checkbox
                colorScheme="c2i"
                onChange={(e) => updateParts(e, "corps")}
                isChecked={parts.includes("corps")}
              >
                Corps
              </Checkbox>
              <Checkbox
                colorScheme="c2i"
                onChange={(e) => updateParts(e, "bouchon")}
                isChecked={parts.includes("bouchon")}
              >
                Fermeture: bouchon / pompe
              </Checkbox>
              <Checkbox
                colorScheme="c2i"
                onChange={(e) => updateParts(e, "tete")}
                isChecked={parts.includes("tete")}
              >
                Tête: cuvette / opercule
              </Checkbox>
            </VStack>

            {parts.length > 0 && (
              <>
                <Box m="15px">
                  2 - Ajoutez les différents tronçons du trajet
                </Box>
                <Button colorScheme="c2i" size="xs" onClick={addTransport}>
                  Ajouter un tronçons
                </Button>
              </>
            )}
            {segments.map((segment: Segment, index: number) => (
              <>
                <HStack key={`${index}_0`} m="10px">
                  <Input
                    w="150px"
                    key={`${index}_1`}
                    bg="white"
                    size="sm"
                    placeholder="distance en km"
                    value={segment.distance}
                    onChange={(e: any) => {
                      handleChange(e.target.value, index, "distance")
                    }}
                  />
                  <Select
                    key={`${index}_2`}
                    w="170px"
                    bg="white"
                    onChange={(e: any) => {
                      handleChange(e.target.value, index, "mode")
                    }}
                    value={segment.mode}
                    size="sm"
                    placeholder="mode de transport"
                  >
                    {transportType.map((type: string, index2: number) => (
                      <option key={index2} value={type}>
                        {type}
                      </option>
                    ))}
                  </Select>
                  {segment.mode !== "" && segment.mode !== "Avion" && (
                    <Select
                      key={`${index}_3`}
                      bg="white"
                      onChange={(e: any) => {
                        handleChange(e.target.value, index, "type")
                      }}
                      value={segment.type}
                      placeholder="type de transport"
                      size="sm"
                    >
                      {option_transport[segment.mode].map(
                        (option: string, index2: number) => (
                          <option key={index2} value={option}>
                            {option}
                          </option>
                        )
                      )}
                    </Select>
                  )}
                </HStack>
              </>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="c2i"
              mr={3}
              onClick={() => {
                saveProcess()
              }}
            >
              Enregistrer
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                resetTransport()
                onClose()
              }}
            >
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
