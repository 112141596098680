import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import React, { useState } from "react"
import { Element, Emballage } from "./utils"

interface IProps {
  setValues: any
  famille_element: string
  values: any
  base: string
}

export default function TableElements({
  setValues,
  famille_element,
  values,
  base,
}: IProps) {
  const removeElement = (e: any, index: number) => {
    let new_elements = values[base][famille_element].elements
    new_elements.splice(index, 1)
    let new_values = values
    new_values[base][famille_element].elements = new_elements
    setValues(new_values)
  }
  const elements = values[base][famille_element].elements
  return (
    <>
      {elements && (
        <Table bg="white" borderRadius={7} size="sm" mt="30px">
          <Thead>
            <Tr bg="c2i.100">
              <Th>Matériau</Th>
              <Th>Sous-matériau</Th>
              <Th>Poids total</Th>
              <Th>Poids recyclé</Th>
              <Th>Procédé de transformation</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {elements.map((element: Element, index: number) => (
              <Tr key={index}>
                <Td>{element.materiau}</Td>
                <Td>{element.sous_materiau}</Td>
                <Td>{element.poids_total}</Td>
                <Td>{element.poids_recycle}</Td>
                <Td>{element.procede_de_transformation}</Td>
                <Td>
                  <Button
                    size="xs"
                    onClick={(e) => removeElement(e, index)}
                    colorScheme="c2i"
                  >
                    Supprimer
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  )
}
