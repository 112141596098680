import { Auth } from "contexts/Auth"
import React, { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"

const AuthenticatedRoute = () => {
  const { isAuthentificated } = useContext(Auth)
  return isAuthentificated ? <Outlet /> : <Navigate to="/login" />
}

export default AuthenticatedRoute
