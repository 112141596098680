import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react"
import { Spinner } from "@chakra-ui/react"
import axios from "axios"
import React, { useState } from "react"
import { config } from "Constants"
interface IProps {
  onClose: () => void
  isOpen: boolean
  onOpen: () => void
  emballageId: string
}

export default function AlertScoring({
  onClose,
  isOpen,
  onOpen,
  emballageId,
}: IProps) {
  const url = config.url.API_URL
  const [isLoading, setIsLoading] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [resultMessage, setResultMessage] = useState<string>("")
  const cancelRef = React.useRef(null)
  const lancerScoring = () => {
    setIsLoading(true)
    axios
      .post(`${url}/scoring/${emballageId}`)
      .then((res) => {
        console.log(res.data)
        setResultMessage(res.data)
      })
      .catch((err) => {
        console.log(err)
        setResultMessage("Le calcul du scoring a échoué")
      })
      .finally(() => {
        setIsLoading(false)
        setShowResult(true)
      })
  }
  const handleClose = () => {
    setShowResult(false)
    onClose()
    window.location.reload()
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            {!isLoading && !showResult && (
              <>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Lancer Calcul de scoring{" "}
                </AlertDialogHeader>

                <AlertDialogBody>
                  Etes-vous sur de vouloir lancer le calcul de scoring ?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button
                    ref={cancelRef}
                    onClick={lancerScoring}
                    colorScheme="c2i"
                  >
                    Lancer le Calcul
                  </Button>
                  <Button colorScheme="red" onClick={handleClose} ml={3}>
                    Annuler
                  </Button>
                </AlertDialogFooter>
              </>
            )}
            {isLoading && (
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <Spinner mr="15px" /> Calcul en cours ...
              </AlertDialogHeader>
            )}
            {showResult && (
              <>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {resultMessage}
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <Button
                    colorScheme={
                      resultMessage === "Calcul du score effectué"
                        ? "c2i"
                        : "red"
                    }
                    onClick={handleClose}
                    ml={3}
                  >
                    Fermer
                  </Button>
                </AlertDialogFooter>
              </>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
