import React, {useEffect, useState} from "react"
import {
    Box,
    Button,
    Checkbox,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Table,
    Tbody,
    Td,
    Th,
    Thead, Tooltip,
    Tr,
    useDisclosure
} from "@chakra-ui/react"
import {config} from "../../Constants"
import {DeleteIcon} from "@chakra-ui/icons"
import axios from "axios"
import UserForm from "./UserForm"
import {Client} from "components/utils"
import {getItem} from "../../services/LocalStorage"
import moment from 'moment';


export type UsersType = {
    _id: string
    name: string
    login: string
    is_admin: boolean
    is_full_access: boolean
    access: Array<string>
    creation_date?: string
    is_free_trial?: boolean
}

export default function Users() {
    // Get list of users
    const [listUsers, setListUsers] = useState<Array<UsersType>>([])
    const [clients, setClients] = useState<Array<Client>>([])
    const [idToDelete, setIdToDelete] = useState<string | undefined>(undefined)
    const [idToModify, setIdToModify] = useState<string | undefined>(undefined)
    const [idToConvert, setIdToConvert] = useState<string | undefined>(undefined)
    const [flagNewUser, setFlagNewUser] = useState<number>(0)
    const [listRefIsLoading, setListRefIsLoading] = useState<boolean>(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose,
    } = useDisclosure()
    const {
        isOpen: isConvertUserOpen,
        onOpen: onConvertUserOpen,
        onClose: onConvertUserClose,
    } = useDisclosure()

    const token = getItem("token")

    useEffect(() => {
        const url = config.url.API_URL
        axios
            .get(url + "/clients/" + token)
            .then((res) => {
                setClients(res.data)
                console.log(res.data)
            })
            .catch((res) => {
                console.log(res)
                //setRefs(res.data)
            })
    }, [])
    useEffect(() => {
        setListRefIsLoading(true)
        const url = config.url.API_URL
        axios
            .get(url + "/users")
            .then((res) => {
                setListUsers(res.data)
                console.log(res.data)
                setListRefIsLoading(false)
            })
            .catch((res) => {
                console.log(res)
                //setRefs(res.data)
            })
    }, [flagNewUser])

    const deleteUser = () => {
        console.log("suppression user")
        console.log(idToDelete)
        const url = config.url.API_URL
        axios
            .delete(url + "/users/" + idToDelete)
            .then((res) => {
                console.log(res)
                onDeleteClose()
                setFlagNewUser((prevValue: any) => prevValue + 1)
            })
            .catch((res) => {
                console.log(res)
                onDeleteClose()
            })
    }

    const convertTrialUser = () => {
        console.log("convert user")
        console.log(idToConvert)
        const url = config.url.API_URL
        axios
            .put(url + "/users/convert-trial-user/" + idToConvert)
            .then((res) => {
                console.log(res)
                onConvertUserClose()
                setFlagNewUser((prevValue: any) => prevValue + 1)
            })
            .catch((res) => {
                console.log(res)
                onConvertUserClose()
            })
    }

    const onCloseAndResetId = () => {
        setIdToModify(undefined)
        onClose()
    }
    return (
        <>
            <Button onClick={onOpen} size="sm" colorScheme="c2i">
                Créer un utilisateur
            </Button>
            <Modal isOpen={isOpen} onClose={onCloseAndResetId}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Utilisateur</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <UserForm
                            clients={clients}
                            idToModify={idToModify}
                            listUsers={listUsers}
                            onClose={onClose}
                            setFlagNewUser={setFlagNewUser}
                            setIdToModify={setIdToModify}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
            {listRefIsLoading ? (
                <Spinner mt="30px" ml="50px"/>
            ) : (
                <Table size="sm" mt="30px">
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Login</Th>
                            <Th>Admin ?</Th>
                            <Th>Client accès complet ?</Th>
                            <Th>Compte démo ?</Th>
                            <Th>Liste des clients accessibles</Th>
                            <Th>Date de création</Th>
                            <Th></Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {listUsers.map((users: UsersType) => (
                            <Tr key={users.name}>
                                <Td>{users.name}</Td>
                                <Td>{users.login}</Td>
                                <Td>
                                    {users.is_admin ? (
                                        <Checkbox isDisabled defaultChecked colorScheme="c2i"/>
                                    ) : (
                                        <Checkbox isDisabled colorScheme="c2i"/>
                                    )}
                                </Td>
                                <Td>
                                    {users.is_full_access ? (
                                        <Checkbox isDisabled defaultChecked colorScheme="c2i"/>
                                    ) : (
                                        <Checkbox isDisabled colorScheme="c2i"/>
                                    )}
                                </Td><Td>
                                {users.is_free_trial ? (
                                    <Checkbox isDisabled defaultChecked colorScheme="c2i"/>
                                ) : (
                                    <Checkbox isDisabled colorScheme="c2i"/>
                                )}
                            </Td>
                                <Td>
                                    {users.access &&
                                        clients &&
                                        users.access.map((client_id, index) => {
                                            const client_name = clients.filter(
                                                (client) => client.id === client_id
                                            )[0]?.name
                                            return <Box key={index}>{client_name}</Box>
                                        })}
                                </Td>
                                <Td>{users.creation_date ? moment(users.creation_date).format("DD/MM/YYYY") : ""}</Td>
                                <Td>
                                    <Button
                                        colorScheme="c2i"
                                        size="sm"
                                        onClick={(event) => {
                                            setIdToDelete(users._id)
                                            onDeleteOpen()
                                        }}
                                    >
                                        <DeleteIcon/>
                                    </Button>
                                </Td>
                                <Td>
                                    <Button
                                        colorScheme="c2i"
                                        size="sm"
                                        onClick={(event) => {
                                            setIdToModify(users._id)
                                            onOpen()
                                        }}
                                    >
                                        Modifier
                                    </Button>
                                </Td><Td>
                                {users.is_free_trial &&
                                    <Tooltip label="Convertir l'utilisateur démo en utilisateur">
                                        <Button
                                            colorScheme="c2i"
                                            size="sm"
                                            onClick={(event) => {
                                            setIdToConvert(users._id)
                                            onConvertUserOpen()
                                        }}
                                        >
                                            Convertir
                                        </Button></Tooltip>}
                            </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
            <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Supprimer Utilisateur</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <HStack spacing="15px">
                            <Button onClick={deleteUser} colorScheme="red">
                                Confirmer
                            </Button>
                            <Button onClick={onDeleteClose} colorScheme="c2i">
                                Annuler
                            </Button>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal isOpen={isConvertUserOpen} onClose={onConvertUserClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Convertir l'utilisateur démo en utilisateur</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <HStack spacing="15px">
                            <Button onClick={convertTrialUser} colorScheme="red">
                                Confirmer
                            </Button>
                            <Button onClick={onConvertUserClose} colorScheme="c2i">
                                Annuler
                            </Button>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
