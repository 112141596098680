import "./App.css"
import React, { useState } from "react"
import Admin from "./components/Admin"
import AddEdit from "./components/Emballages/AddEdit"
import Emballages from "./components/Emballages/Emballages"
import FreeTrial from "./components/FreeTrial"
import Login from "./components/Login"
import Navbar from "./components/Navbar"
import Header from "./components/Header"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import { hasAuthentificated } from "./services/AuthApi"
import { Auth } from "./contexts/Auth"
import AuthenticatedRoute from "./components/AuthenticatedRoute"
import AuthenticatedRouteAdmin from "./components/AuthenticatedRouteAdmin"
import { Flex, Box } from "@chakra-ui/react"
import axios from "axios";
import {getItem} from "./services/LocalStorage";

const token = getItem("token")
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;


function App() {
  const [isAuthentificated, setIsAuthentificated] = useState(
    hasAuthentificated()
  )
  return (
    <Auth.Provider value={{ isAuthentificated, setIsAuthentificated }}>
      <BrowserRouter>
        {isAuthentificated ? (
          <>
              <FreeTrial />
            <Header />
            <Flex direction="row">
              <Box w="150px" h="auto" minH="1000px" bg="c2i.600">
                <Navbar />
              </Box>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate replace to="/emballages" />}
                />

                <Route
                  exact
                  path="/admin"
                  element={<AuthenticatedRouteAdmin />}
                >
                  <Route exact path="/admin" element={<Admin />} />
                </Route>

                <Route
                  exact
                  path="/emballages"
                  element={<AuthenticatedRoute />}
                >
                  <Route exact path="/emballages" element={<Emballages />} />
                </Route>
                <Route path="/emballages/add" element={<AddEdit />} />
                <Route
                  path="/emballages/edit/:emballageId"
                  element={<AddEdit />}
                />
              </Routes>
            </Flex>
          </>
        ) : (
          <Login />
        )}
      </BrowserRouter>
    </Auth.Provider>
  )
}
export default App
