import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { ChakraProvider } from "@chakra-ui/react"
import { extendTheme } from "@chakra-ui/react"



const theme = extendTheme({
  colors: {
    c2i: {
      50: "#e5faf5",
      100: "#c8e8e0",
      200: "#a8d7cb",
      300: "#87c7b6",
      400: "#66b6a1",
      500: "#4d9c88",
      600: "#29594D",
      700: "#28574c",
      800: "#14342d",
      900: "#00130f",
    },
  },
})

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
)
