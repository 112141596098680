import {ScoringEmballage, CarbonFootPrint, Element} from "./utils"

// new function
export function get_carbon_footer_print(
  scoring: ScoringEmballage | undefined,
  isBenchmark: boolean
) {
  if (scoring === undefined || scoring === null) {
    return 0
  }
  const scoring_emballage = isBenchmark
    ? scoring.scoring_benchmark
    : scoring.scoring_emballage

  let carbon_footprint = 0
  if (
    scoring_emballage !== undefined &&
    scoring_emballage?.carbon_footprint !== null
  ) {
    scoring_emballage?.carbon_footprint.forEach((x: CarbonFootPrint) => {
      carbon_footprint += x.raw_material_production
      carbon_footprint += x.packaging_production
      carbon_footprint += x.transport
      carbon_footprint += x.end_of_life
    })
  }
  return carbon_footprint
}

export function get_eco_circularite(
  scoring: ScoringEmballage | undefined,
  isBenchmark: boolean
) {
  if (scoring === undefined || scoring === null) {
    return 0
  }
  const scoring_emballage = isBenchmark
    ? scoring.scoring_benchmark
    : scoring.scoring_emballage

  let eco_circularite = 0
  if (
    scoring_emballage !== undefined &&
    scoring_emballage.recyclability !== null
  ) {
    eco_circularite =
      scoring_emballage.recyclability.score_total_économie_circulaire
  }
  return eco_circularite
}

export function get_carbon_footer_print_detail(
  scoring: ScoringEmballage | undefined,
  isBenchmark: boolean
) {
  if (scoring === undefined || scoring === null) {
    return { production: 0, transport: 0, end_of_life: 0, total: 0 }
  }
  const scoring_emballage = isBenchmark
    ? scoring.scoring_benchmark
    : scoring.scoring_emballage

  let production = 0
  let transport = 0
  let end_of_life = 0
  if (
    scoring_emballage !== undefined &&
    scoring_emballage?.carbon_footprint !== null
  ) {
    scoring_emballage?.carbon_footprint.forEach((x: CarbonFootPrint) => {
      production += x.raw_material_production
      production += x.packaging_production
      transport += x.transport
      end_of_life += x.end_of_life
    })
  }
  return {
    production: production,
    transport: transport,
    end_of_life: end_of_life,
    total: production + transport + end_of_life,
  }
}

export const getRecycledWeightPartFormElements = (elements: Element[] | undefined | null) => {
    let recycled_weight = 0
    let total_weight = 0
    if (!elements) return undefined
    elements.forEach((x: Element) => {
        recycled_weight += parseFloat(x.poids_recycle)
        total_weight += parseFloat(x.poids_total)
    })
    if (total_weight === 0) return undefined
    return Math.round(recycled_weight / total_weight * 100).toString()
}
