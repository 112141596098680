import React, {useContext, useEffect, useState} from "react"
import {Center, Flex, Spacer} from "@chakra-ui/react"
import {Auth} from "../contexts/Auth"
import {getItem} from "../services/LocalStorage"
import jwt_decode from "jwt-decode"
import {Payload, UserResponse} from "./utils"
import {config} from "../Constants";
import axios from "axios";


export default function Header() {
    const {isAuthentificated, setIsAuthentificated} = useContext(Auth)
    const [isFreeTrial, setIsFreeTrial] = useState(false)
    const [user, setUser] = useState<UserResponse | undefined>(undefined)

    useEffect(() => {
        const url = config.url.API_URL
        axios
            .get(url + "/me")
            .then((res) => {
                setUser(res.data)
                console.log(res.data)
            })
            .catch((res) => {
                console.log(res)
                //setRefs(res.data)
            })
    }, [isAuthentificated])

    useEffect(() => {
        const token = getItem("token")
        if (token === null) {
            return
        }
        //payload jwt token
        const payload: Payload = jwt_decode(token)
        console.log(payload)
        payload.is_free_trial && setIsFreeTrial(payload.is_free_trial)
    }, [isAuthentificated])
    return (<>
            {
                isFreeTrial ? <>
                    <Flex
                        w="100%"
                        h="30px"
                        align="center"
                        borderBottom="solid 1px"
                        backgroundColor="#fff44f"
                        borderColor="#D6DCE0"
                    ><Spacer>
                        <Center>Demo Account <LeftOperations user={user}/></Center></Spacer>
                    </Flex></> : <></>
            }</>
    )
}


function LeftOperations({user}: { user: UserResponse | undefined }) {
    console.log(user)
    return <>{user !== undefined && user.trial_operations_left != undefined ?
        <> - {user.trial_operations_left} opération(s) de création d'emballage restante(s)</> : null}</>
}
