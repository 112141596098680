import ElementForm from "./ElementForm"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react"
import { E } from "./utils"
interface IProps {
  isOpen: boolean
  onClose: () => void
  referentiel: any
  setValues: any
  values: any
  base: any
  famille_element: E
}
export default function ModalNewElement({
  isOpen,
  onClose,
  referentiel,
  setValues,
  values,
  base,
  famille_element,
}: IProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nouvel élément</ModalHeader>

        <ModalBody>
          <ElementForm
            onClose={onClose}
            referentiel={referentiel}
            setValues={setValues}
            values={values}
            famille_element={famille_element}
            base={base}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
