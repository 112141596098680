import { Box, Flex, Text, Thead, VStack } from "@chakra-ui/react"
import { EmballageWithBenchmark } from "./utils"
import {
  Table,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react"
import NumberFormat from "react-number-format"
import { get_carbon_footer_print_detail } from "./utils_scoring"
import { Client } from "components/utils"
import { FournisseursType } from "components/Admin/Fournisseurs"
import ScoringDetailDescription from "./ScoringDetailDescription"
import { Alert, AlertIcon } from "@chakra-ui/react"
interface IProps {
  emballageWithBenchmark: EmballageWithBenchmark | undefined
  clients: Array<Client> | undefined
  listFournisseurs: Array<FournisseursType> | undefined
}

export default function ScoringDetail({
  emballageWithBenchmark,
  clients,
  listFournisseurs,
}: IProps) {
  const scoring = emballageWithBenchmark?.scoring?.scoring_emballage
  const scoring_benchmark = emballageWithBenchmark?.is_benchmark_active
    ? emballageWithBenchmark?.scoring?.scoring_benchmark
    : undefined
  const emballage_score = get_carbon_footer_print_detail(
    emballageWithBenchmark?.scoring,
    false
  )
  const score_benchmark = get_carbon_footer_print_detail(
    emballageWithBenchmark?.scoring,
    true
  )
  const emballage_1_name =
    emballageWithBenchmark?.emballage?.name &&
    emballageWithBenchmark?.emballage?.name !== ""
      ? emballageWithBenchmark?.emballage?.name
      : "emballage 1"

  const emballage_2_name =
    emballageWithBenchmark?.benchmark?.name &&
    emballageWithBenchmark?.benchmark?.name !== "" &&
    emballageWithBenchmark.is_benchmark_active
      ? emballageWithBenchmark?.benchmark?.name
      : "emballage 2"

  return (
    <Box w="900px">
      {scoring !== undefined && emballageWithBenchmark ? (
        <>
          <Box textTransform="uppercase" fontWeight={800} ml="20px">
            {emballageWithBenchmark?.name}
          </Box>
          <Box textTransform="uppercase" fontWeight={800} ml="20px">
            {clients
              ? clients?.filter(
                  (c) => c.id === emballageWithBenchmark?.client_id
                )[0].name
              : null}
          </Box>
          {emballageWithBenchmark.is_benchmark_active === false && (
            <Alert status="warning" colorScheme="c2i" w="550px">
              <AlertIcon />
              Attention : aucun benchmark défini pour cet emballage !
            </Alert>
          )}
          <Box
            fontSize={15}
            ml="20px"
          >{`Quantité: ${emballageWithBenchmark?.quantity} unités`}</Box>

          <Flex>
            <ScoringDetailDescription
              listFournisseurs={listFournisseurs}
              emballageWithBenchmark={emballageWithBenchmark}
              emballageOrBenchmark="emballage"
              emballageName={emballage_1_name}
            />
            {emballageWithBenchmark.is_benchmark_active && (
              <ScoringDetailDescription
                listFournisseurs={listFournisseurs}
                emballageWithBenchmark={emballageWithBenchmark}
                emballageOrBenchmark="benchmark"
                emballageName={emballage_2_name}
              />
            )}
          </Flex>

          <VStack align="left" spacing={15} mt="30px">
            <Box>
              <Text textTransform="uppercase" fontWeight={800}>
                Emprunte Carbone
              </Text>
              <TableContainer>
                <Table variant="simple" w="300px" size="sm" mt="20px">
                  <Thead>
                    <Tr>
                      <Th textTransform="none">
                        {emballageWithBenchmark?.name}
                      </Th>
                      <Th textTransform="none">
                        <VStack>
                          <Box>{emballage_1_name}</Box>
                          <Box>gCO2eq</Box>
                        </VStack>
                      </Th>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Th textTransform="none">
                          <VStack>
                            <Box>{emballage_2_name}</Box>
                            <Box>gCO2eq</Box>
                          </VStack>
                        </Th>
                      )}
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Th textTransform="none">Différence</Th>
                      )}
                      <Th textTransform="none">
                        <VStack>
                          <Box>{emballage_1_name}</Box>
                          <Box>commande - kgCO2eq</Box>
                        </VStack>
                      </Th>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Th textTransform="none">
                          <VStack>
                            <Box>{emballage_2_name}</Box>
                            <Box>commande - kgCO2eq</Box>
                          </VStack>
                        </Th>
                      )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Production</Td>
                      <Td>
                        <NumberFormat
                          value={emballage_score.production}
                          displayType="text"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={score_benchmark.production}
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              (score_benchmark.production /
                                emballage_score.production -
                                1) *
                              100
                            }
                            suffix=" %"
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <NumberFormat
                          value={
                            (emballage_score.production *
                              parseInt(emballageWithBenchmark.quantity)) /
                            1000
                          }
                          displayType="text"
                          decimalScale={2}
                        />
                      </Td>

                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              (score_benchmark.production *
                                parseInt(emballageWithBenchmark.quantity)) /
                              1000
                            }
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                    </Tr>

                    <Tr>
                      <Td>Transport</Td>
                      <Td>
                        <NumberFormat
                          value={emballage_score.transport}
                          displayType="text"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={score_benchmark.transport}
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              (score_benchmark.transport /
                                emballage_score.transport -
                                1) *
                              100
                            }
                            suffix=" %"
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <NumberFormat
                          value={
                            (emballage_score.transport *
                              parseInt(emballageWithBenchmark.quantity)) /
                            1000
                          }
                          displayType="text"
                          decimalScale={2}
                        />
                      </Td>

                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              (score_benchmark.transport *
                                parseInt(emballageWithBenchmark.quantity)) /
                              1000
                            }
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                    </Tr>
                    <Tr>
                      <Td>Fin de vie</Td>
                      <Td>
                        <NumberFormat
                          value={emballage_score.end_of_life}
                          displayType="text"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={score_benchmark.end_of_life}
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              (score_benchmark.end_of_life /
                                emballage_score.end_of_life -
                                1) *
                              100
                            }
                            suffix=" %"
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <NumberFormat
                          value={
                            (emballage_score.end_of_life *
                              parseInt(emballageWithBenchmark.quantity)) /
                            1000
                          }
                          displayType="text"
                          decimalScale={2}
                        />
                      </Td>

                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              (score_benchmark.end_of_life *
                                parseInt(emballageWithBenchmark.quantity)) /
                              1000
                            }
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>Total</Th>
                      <Td>
                        <NumberFormat
                          value={emballage_score.total}
                          displayType="text"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={score_benchmark.total}
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              (score_benchmark.total / emballage_score.total -
                                1) *
                              100
                            }
                            suffix=" %"
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <NumberFormat
                          value={
                            (emballage_score.total *
                              parseInt(emballageWithBenchmark.quantity)) /
                            1000
                          }
                          displayType="text"
                          decimalScale={2}
                        />
                      </Td>

                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              (score_benchmark.total *
                                parseInt(emballageWithBenchmark.quantity)) /
                              1000
                            }
                            displayType="text"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </Box>
            <Box>
              <Text textTransform="uppercase" fontWeight={800}>
                Economie circulaire
              </Text>
              <TableContainer>
                <Table variant="simple" size="sm" mt="20px">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th textTransform="none">{emballage_1_name}</Th>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Th textTransform="none">{emballage_2_name}</Th>
                      )}
                      <Th>Interprétation</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Score de recyclabilité</Td>
                      <Td>
                        <NumberFormat
                          value={scoring.recyclability.score_recylabilité * 100}
                          displayType="text"
                          suffix=" %"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              scoring_benchmark
                                ? scoring_benchmark.recyclability
                                    .score_recylabilité * 100
                                : 0
                            }
                            displayType="text"
                            suffix=" %"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <Box w="300px" whiteSpace="normal">
                          Score de recyclabilité théorique de l'emballage
                          analysé
                        </Box>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Score de recyclage effectif</Td>
                      <Td>
                        <NumberFormat
                          value={
                            scoring.recyclability.score_recyclage_effectif * 100
                          }
                          displayType="text"
                          suffix=" %"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              scoring_benchmark
                                ? scoring_benchmark.recyclability
                                    .score_recyclage_effectif * 100
                                : 0
                            }
                            displayType="text"
                            suffix=" %"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <Box w="300px" whiteSpace="normal">
                          % de recyclage effectif de ce type d'emballage en
                          général et de la zone géographique
                        </Box>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Sous-score recyclage</Td>
                      <Td>
                        <NumberFormat
                          value={scoring.recyclability.sous_score_total * 100}
                          displayType="text"
                          suffix=" %"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              scoring_benchmark
                                ? scoring_benchmark.recyclability
                                    .sous_score_total * 100
                                : 0
                            }
                            displayType="text"
                            suffix=" %"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <Box w="300px" whiteSpace="normal">
                          Niveau de recyclabilité réel estimé de l'emballage
                          analysé
                        </Box>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>% de MP recyclées</Td>
                      <Td>
                        <NumberFormat
                          value={
                            scoring.recyclability.bonus_matière_recyclée * 100
                          }
                          displayType="text"
                          suffix=" %"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              scoring_benchmark
                                ? scoring_benchmark.recyclability
                                    .bonus_matière_recyclée * 100
                                : 0
                            }
                            displayType="text"
                            suffix=" %"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <Box w="300px" whiteSpace="normal">
                          Bonus en fonction du % de matière recyclé dans
                          l'emballage analysé
                        </Box>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Score économie circulaire</Td>
                      <Td>
                        <NumberFormat
                          value={
                            scoring.recyclability
                              .score_total_économie_circulaire * 100
                          }
                          displayType="text"
                          suffix=" %"
                          decimalScale={2}
                        />
                      </Td>
                      {emballageWithBenchmark.is_benchmark_active && (
                        <Td>
                          <NumberFormat
                            value={
                              scoring_benchmark
                                ? scoring_benchmark.recyclability
                                    .score_total_économie_circulaire * 100
                                : 0
                            }
                            displayType="text"
                            suffix=" %"
                            decimalScale={2}
                          />
                        </Td>
                      )}
                      <Td>
                        <Box w="300px" whiteSpace="normal">
                          Score finale de l'emballage (0% très mauvais / 100%
                          excellent)
                        </Box>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </VStack>
        </>
      ) : null}
    </Box>
  )
}
