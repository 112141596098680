import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertTitle,
  Box,
  Button,
  HStack,
  Select,
  VStack,
  Text,
  useDisclosure,
  Input,
  SimpleGrid,
} from "@chakra-ui/react"

import { SwitchControl } from "formik-chakra-ui"
import { useState, useEffect } from "react"
import CorpsBouchonForm from "./CorpsBouchonForm"
import CustomInput from "./CustomInput"
import ModalNewElement from "./ModalNewElement"
import NewTransport from "./NewTransport"
import TableElements from "./TableElements"
import { I, Transport } from "./utils"

interface IProps {
  values: any
  setValues: any
  handleChange: any
  base: I
  referentiel: any
  errors: any
  touched: any
  fournisseursList: any
}

function EmballageForm({
  handleChange,
  values,
  setValues,
  base,
  referentiel,
  errors,
  touched,
  fournisseursList,
}: IProps) {
  const [showNewCorpsElement, setShowNewCorpsElement] = useState(false)
  const [choixAdditif, setChoixAdditif] = useState([])
  const [choixCouleur, setChoixCouleur] = useState([])
  const [choixEncre, setChoixEncre] = useState([])
  const [choixAdhesif, setChoixAdhesif] = useState([])
  const [typeCamion, setTypeCamion] = useState([])
  const [typeTrain, setTypeTrain] = useState([])
  const [typeBateau, setTypeBateau] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    if (referentiel !== undefined) {
      setChoixAdditif(
        referentiel["referentiel"]["Recyclabilité"]
          .filter((x: any) => x["Catégorie"] === "Additif")
          .map((x: any) => x["Type"])
      )
      setChoixCouleur(
        referentiel["referentiel"]["Recyclabilité"]
          .filter((x: any) => x["Catégorie"] === "Couleur")
          .map((x: any) => x["Type"])
      )
      setChoixEncre(
        referentiel["referentiel"]["Recyclabilité"].filter(
          (x: any) => x["Catégorie"] === "Encre"
        )
      )
      setChoixAdhesif(
        referentiel["referentiel"]["Recyclabilité"].filter(
          (x: any) => x["Catégorie"] === "Colles et adhésifs"
        )
      )
      setTypeCamion(
        referentiel["referentiel"]["Transport camion"].map(
          (x: any) => x["Type"]
        )
      )
      setTypeTrain(
        referentiel["referentiel"]["Transport train"].map((x: any) => x["Pays"])
      )
      setTypeBateau(
        referentiel["referentiel"]["Transport bateau"].map(
          (x: any) => x["Type"]
        )
      )
    }
  }, [referentiel])

  const cardProps = {
    bg: "gray.100",
    borderRadius: "0px",
    p: "10px",
    mt: "0px",
  }
  const buttonElementProps = {
    size: "sm",
    colorScheme: "blue",
    w: "150px",
    mt: "10px",
  }
  const choix_matiere = [
    "PE, PP, OPP",
    "IML base PE",
    "IML base PP",
    "Papier",
    "Autres",
  ]
  const option_transport = {
    Camion: typeCamion,
    Train: typeTrain,
    Bateau: typeBateau,
  }
  const removeTransport = (e: any, index: number) => {
    let new_elements = values[base].transports
    new_elements.splice(index, 1)
    let new_values = values
    new_values[base].transports = new_elements
    setValues(new_values)
  }

  const saveValuesRatio = (e: any) => {
    let new_values = values
    new_values[base].ratio_vente_france = parseInt(e.target.value)
    new_values[base].ratio_vente_europe = 100 - parseInt(e.target.value)
    setValues(new_values)
  }
  return (
    <>
      {referentiel !== undefined && (
        <Accordion allowMultiple>
          <Text fontSize="5xl" mt="10px">
            {base}
          </Text>
          <SimpleGrid columns={2} spacing={10} mt="20px">
            <Box>
              <Text>Nom</Text>
              <Input
                size="sm"
                id={base + ".name"}
                name={`${base}.name`}
                value={values[base].name}
                onChange={handleChange}
              />
              <Text>Type d'emballage</Text>
              <Select
                bg="white"
                size="sm"
                name={base + ".type"}
                onChange={handleChange}
                value={values[base].type}
                w="200px"
              >
                <option value="" key=""></option>
                <option value="Tube" key="Tube">
                  Tube
                </option>
                <option value="Flacon" key="Flacon">
                  Flacon
                </option>
                <option value="Pot" key="Pot">
                  Pot
                </option>
                <option value="Autre" key="Autre">
                  Autre
                </option>
              </Select>
              <Box w="200px">
                <CustomInput
                  name={base + ".contenance"}
                  handleChange={handleChange}
                  value={values[base].contenance}
                  label="contenance en ml"
                />
              </Box>

              {errors[base]?.contenance ? (
                <Alert status="warning" height="10px" fontSize={12}>
                  <AlertTitle>{errors[base].contenance}</AlertTitle>
                </Alert>
              ) : null}
            </Box>
            <Box>
              <Box w="200px">
                <VStack align="left">
                  <Text>% des ventes en France</Text>
                  <Input
                    bg="white"
                    size="xs"
                    id={base + ".ratio_vente_france"}
                    name={base + ".ratio_vente_france"}
                    type="text"
                    onChange={saveValuesRatio}
                    value={values[base].ratio_vente_france}
                  />
                </VStack>
              </Box>
              {errors[base]?.ratio_vente_france ? (
                <Alert status="warning" height="10px" fontSize={12}>
                  <AlertTitle>{errors[base].ratio_vente_france}</AlertTitle>
                </Alert>
              ) : null}
              <Box w="200px">
                <VStack align="left">
                  <Text>% des ventes en Europe</Text>
                  <Input
                    isDisabled={true}
                    bg="white"
                    size="xs"
                    id={base + ".ratio_vente_europe"}
                    name={base + ".ratio_vente_europe"}
                    type="text"
                    //onChange={handleChange}
                    value={values[base].ratio_vente_europe}
                  />
                </VStack>
              </Box>
            </Box>
          </SimpleGrid>

          <AccordionItem mt="20px">
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Corps
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box {...cardProps}>
                <CorpsBouchonForm
                  part="corps"
                  handleChange={handleChange}
                  values={values}
                  base={base}
                  fournisseursList={fournisseursList}
                  choixAdditif={choixAdditif}
                  choixCouleur={choixCouleur}
                  choixEncre={choixEncre}
                  choix_matiere={choix_matiere}
                  choixAdhesif={choixAdhesif}
                  buttonElementProps={buttonElementProps}
                  setValues={setValues}
                  referentiel={referentiel}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Fermeture: bouchon / pompe
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box {...cardProps}>
                <CorpsBouchonForm
                  part="bouchon"
                  handleChange={handleChange}
                  values={values}
                  base={base}
                  fournisseursList={fournisseursList}
                  choixAdditif={choixAdditif}
                  choixCouleur={choixCouleur}
                  choixEncre={choixEncre}
                  choix_matiere={choix_matiere}
                  choixAdhesif={choixAdhesif}
                  buttonElementProps={buttonElementProps}
                  setValues={setValues}
                  referentiel={referentiel}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Tête: cuvette / opercule
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box {...cardProps}>
                <Text>Fournisseur*</Text>
                <Select
                  size="sm"
                  onChange={handleChange}
                  value={values[base].tete.fournisseur_id}
                  name={base + ".tete.fournisseur_id"}
                  placeholder="Choisir un fournisseur"
                  bg="white"
                >
                  {fournisseursList !== undefined &&
                    fournisseursList.map((x: any, index: any) => (
                      <option value={x._id} key={index}>
                        {x.name}
                      </option>
                    ))}
                </Select>

                <SwitchControl
                  name={base + ".tete.is_detachable"}
                  label="Détachable facilement ?"
                />
                <Button
                  {...buttonElementProps}
                  onClick={onOpen}
                  colorScheme="c2i"
                >
                  Nouvel élement
                </Button>

                <ModalNewElement
                  onClose={onClose}
                  isOpen={isOpen}
                  referentiel={referentiel}
                  setValues={setValues}
                  values={values}
                  base={base}
                  famille_element="tete"
                />

                <TableElements
                  setValues={setValues}
                  values={values}
                  famille_element={"tete"}
                  base={base}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Transport
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box {...cardProps}>
                <NewTransport
                  option_transport={option_transport}
                  values={values}
                  setValues={setValues}
                  base={base}
                />
                {values[base].transports &&
                  values[base].transports.map(
                    (transport: Transport, index: number) => (
                      <HStack
                        key={index}
                        bg="white"
                        m="10px"
                        p="10px"
                        borderRadius={7}
                      >
                        <VStack w="500px" align="left">
                          <Box fontWeight={600} ml="0px" mt="7px">
                            Transport {index + 1} - {transport.parts.join(", ")}
                          </Box>

                          {transport.segments.map((segment, index2) => (
                            <Box fontSize={14} key={index2}>
                              Tronçons {index2 + 1}: {segment.distance} km,{" "}
                              {segment.mode}, type : {segment.type}
                            </Box>
                          ))}
                        </VStack>
                        <Box w="100px">
                          <Button
                            colorScheme="c2i"
                            size="sm"
                            onClick={(e) => removeTransport(e, index)}
                          >
                            Supprimer
                          </Button>
                        </Box>
                      </HStack>
                    )
                  )}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  )
}

export default EmballageForm
