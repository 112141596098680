import React, { useEffect, useState } from "react"
import { Button, HStack, Input, Select, VStack } from "@chakra-ui/react"
import { E, I, InitialValues } from "./utils"

interface IProps {
  setValues: any
  famille_element: E
  values: InitialValues
  base: I
  referentiel: any
  onClose: () => void
}

export default function ElementForm({
  values,
  setValues,
  famille_element,
  base,
  referentiel,
  onClose,
}: IProps) {
  //const path = "/emballages/list"
  const [materiau, setMateriau] = useState(undefined)
  const [sous_materiau, setSous_materiau] = useState("")
  const [poids_total, SetPoids_total] = useState("")
  const [poids_recycle, SetPoids_recycle] = useState("")
  const [procede_de_transformation, setProcede_de_transformation] =
    useState(undefined)
  const [listMateriau, setListMateriau] = useState(undefined)
  const [listSousMateriau, setListSousMateriau] = useState(undefined)

  useEffect(() => {
    console.log(referentiel["referentiel"]["MP FE"])
    const list_materiau = referentiel["referentiel"]["MP FE"]
      .map((x: any) => x["Matériau"])
      .filter((v: any, i: any, a: any) => a.indexOf(v) === i)
    setListMateriau(list_materiau)
  }, [])

  const handleChangeMateriau = (event: any) => setMateriau(event.target.value)
  const handleChangeSous_materiau = (event: any) =>
    setSous_materiau(event.target.value)
  const handleChangePoids_total = (event: any) =>
    SetPoids_total(event.target.value)
  const handlePoids_recycle = (event: any) =>
    SetPoids_recycle(event.target.value)
  const handleChangeProcede_de_transformation = (event: any) =>
    setProcede_de_transformation(event.target.value)

  const cancel = () => {
    setMateriau(undefined)
    setSous_materiau("")
    SetPoids_total("")
    SetPoids_recycle("")
    setProcede_de_transformation(undefined)
    onClose()
  }

  const saveElement = () => {
    if (!materiau) {
      alert("Veuillez choisir un matériau")
      return
    }
    if (!sous_materiau) {
      alert("Veuillez choisir un sous matériau")
      return
    }
    if (poids_total === "" || poids_recycle === "") {
      alert("Veuillez remplir les champs poids total et poids recycle")
      return
    }
    if (isNaN(Number(poids_total))) {
      alert("Poids total non valide")
      return
    }
    if (isNaN(Number(poids_recycle))) {
      alert("Poids recyclé non valide")
      return
    }
    const new_element = {
      materiau: materiau,
      sous_materiau: sous_materiau,
      poids_total: poids_total,
      poids_recycle: poids_recycle,
      procede_de_transformation: procede_de_transformation,
    }

    setValues((prev: any) => {
      console.log(base)
      console.log(famille_element)
      const old_elements = prev[base][famille_element].elements
        ? prev[base][famille_element].elements
        : undefined
      console.log(prev[base][famille_element])
      const new_elements =
        old_elements === undefined
          ? [new_element]
          : [...old_elements, new_element]
      const new_prev = { ...prev }
      new_prev[base][famille_element].elements = new_elements
      return new_prev
    })

    setMateriau(undefined)
    setSous_materiau("")
    SetPoids_total("")
    SetPoids_recycle("")
    setProcede_de_transformation(undefined)
    onClose()
  }
  return (
    <>
      <VStack spacing="15px" w="300px" mt="20px" ml="15px">
        <Select
          placeholder="Choisir un matériau"
          size="sm"
          onChange={handleChangeMateriau}
        >
          {referentiel["referentiel"]["MP FE"]
            .map((x: any) => x["Matériau"])
            .filter((v: any, i: any, a: any) => a.indexOf(v) === i)
            .map((x: any) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
        </Select>

        {materiau && (
          <Select
            isRequired
            placeholder="Choisir un sous matériau"
            size="sm"
            onChange={handleChangeSous_materiau}
          >
            {referentiel["referentiel"]["MP FE"]
              .filter((x: any) => x["Matériau"] === materiau)
              .map((x: any) => x["Sous-matériau"])
              .filter((v: any, i: any, a: any) => a.indexOf(v) === i)
              .map((x: any) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
          </Select>
        )}
        {materiau && (
          <Select
            isRequired
            placeholder="Choisir un procédé de transformation"
            size="sm"
            onChange={handleChangeProcede_de_transformation}
          >
            {referentiel["referentiel"]["Procédés FE"]
              .filter((x: any) => x["Matériau"] === materiau)
              .map((x: any) => x["Procédé"])
              .filter((v: any, i: any, a: any) => a.indexOf(v) === i)
              .map((x: any) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
          </Select>
        )}

        <Input
          value={poids_total}
          placeholder="poids_total en g"
          onChange={handleChangePoids_total}
          size="sm"
        />
        <Input
          value={poids_recycle}
          placeholder="poids_recycle en g"
          onChange={handlePoids_recycle}
          size="sm"
        />
      </VStack>
      <HStack align="right" mt="30px">
        <Button size="sm" colorScheme="c2i" mr={3} onClick={cancel}>
          Annuler
        </Button>
        <Button size="sm" onClick={saveElement} variant="ghost">
          Enregistrer
        </Button>
      </HStack>
    </>
  )
}
