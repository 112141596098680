import { useEffect, useState } from "react"
import { Button, Spinner } from "@chakra-ui/react"
import { config } from "../../Constants"
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import { useDisclosure } from "@chakra-ui/react"
import axios from "axios"
import FournisseurForm from "./FournisseurForm"

export type FournisseursType = {
  _id: string
  name: string
  pays: string
  factory_elec_mix: number | undefined | null
  energy_consumption: number | undefined | null
  annual_production: number | undefined | null
  creation_date: Date
  updated_date: Date
}

export default function Fournisseurs() {
  // Get list of users

  const [listFournisseurs, setListFournisseurs] = useState<
    Array<FournisseursType>
  >([])
  const [idToModify, setIdToModify] = useState<string | undefined>(undefined)
  const [flagNewFournisseur, setFlagNewFournisseur] = useState<number>(0)
  const [listRefIsLoading, setListRefIsLoading] = useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    setListRefIsLoading(true)
    const url = config.url.API_URL
    axios
      .get(url + "/fournisseurs")
      .then((res) => {
        setListFournisseurs(res.data)
        console.log(res.data)
        setListRefIsLoading(false)
      })
      .catch((res) => {
        console.log(res)
        //setRefs(res.data)
      })
  }, [flagNewFournisseur])

  const onCloseAndResetId = () => {
    setIdToModify(undefined)
    onClose()
  }
  return (
    <>
      <Button onClick={onOpen} size="sm" colorScheme="c2i">
        Créer un fournisseur
      </Button>
      <Modal isOpen={isOpen} onClose={onCloseAndResetId}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier fournisseur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FournisseurForm
              idToModify={idToModify}
              listFournisseurs={listFournisseurs}
              onClose={onClose}
              setFlagNewFournisseur={setFlagNewFournisseur}
              setIdToModify={setIdToModify}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {listRefIsLoading ? (
        <Spinner mt="30px" ml="50px" />
      ) : (
        <Table size="sm" mt="30px">
          <Thead>
            <Tr>
              <Th>Nom</Th>
              <Th>Pays</Th>
              <Th>Mix électrique de l'usine (kg CO2eq/kWh) ?</Th>
              <Th>Consommation énergétique (MWh)</Th>
              <Th>Production annuelle (t)</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {listFournisseurs.map((users: FournisseursType) => (
              <Tr key={users.name}>
                <Td>{users.name}</Td>
                <Td>{users.pays}</Td>
                <Td>{users.factory_elec_mix}</Td>
                <Td>{users.energy_consumption}</Td>
                <Td>{users.annual_production}</Td>

                <Td>
                  <Button
                    colorScheme="c2i"
                    size="sm"
                    onClick={(event) => {
                      setIdToModify(users._id)
                      onOpen()
                    }}
                  >
                    Modifier
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  )
}
