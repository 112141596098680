const prod = {
  url: {
    API_URL: "https://c2i-backend-webapp.herokuapp.com/api",
  },
}
const dev = {
  url: {
    API_URL: "http://localhost:8000/api",
  },
}
export const config = process.env.NODE_ENV === "development" ? dev : prod
