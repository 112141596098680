import { useEffect, useState } from "react"
import { Button, IconButton, Spinner, Tooltip } from "@chakra-ui/react"
import { config } from "../../Constants"
import { Table, Thead, Tbody, Tr, Th, Td, Input } from "@chakra-ui/react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import { useDisclosure } from "@chakra-ui/react"
import { AddIcon, EditIcon } from "@chakra-ui/icons"
import axios from "axios"
import { getItem } from "../../services/LocalStorage"

type Refs = {
  id: string
  name: string
  date: string
}

export default function Clients() {
  // Get list of Clients
  const [refs, setRefs] = useState<Array<Refs>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [flagNewRef, setFlagNewRef] = useState<number>(0)
  const [listRefIsLoading, setListRefIsLoading] = useState<boolean>(false)
  const [newRefName, setNewRefName] = useState<string>("")
  const [modifyRefName, setModifyRefName] = useState<string>("")
  const [idToModify, setIdToModify] = useState<string>("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isModifyOpen,
    onOpen: onModifyOpen,
    onClose: onModifyClose,
  } = useDisclosure()
  const url = config.url.API_URL
  const token = getItem("token")
  useEffect(() => {
    setListRefIsLoading(true)
    const url = config.url.API_URL
    axios
      .get(url + "/clients/" + token)
      .then((res) => {
        setRefs(res.data)
        setListRefIsLoading(false)
      })
      .catch((res) => {
        console.log(res)
      })
  }, [flagNewRef])

  const creationRef = async () => {
    const verif = refs.find((ref) => ref.name === newRefName)
    if (verif) {
      alert("Ce nom de client existe déjà")
      return
    }
    if (newRefName === "") {
      alert("Veuillez saisir un nom de client")
      return
    }
    setIsLoading(true)

    await axios
      .post(url + "/clients/new", {
        name: newRefName,
      })
      .then(function (response: any) {
        console.log(response)
        setIsLoading(false)
        onClose()
        setFlagNewRef(1)
        return
      })
      .catch(function (error: any) {
        console.log(error)
        return
      })
    setIsLoading(false)
  }
  const modifyClient = async () => {
    const verif = refs.find((ref) => ref.name === modifyRefName)
    if (verif) {
      alert("Ce nom de client existe déjà")
      return
    }
    if (modifyRefName === "") {
      alert("Veuillez saisir un nom de client")
      return
    }
    //setIsLoading(true)

    await axios
      .put(url + "/clients/" + idToModify, {
        name: modifyRefName,
      })
      .then(function (response: any) {
        console.log(response)
        setIsLoading(false)
        onModifyClose()
        setFlagNewRef(1)
        return
      })
      .catch(function (error: any) {
        console.log(error)
        return
      })
    //setIsLoading(false)
  }
  return (
    <>
      <Button onClick={onOpen} size="sm">
        Créer un client
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nouveau client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Nom"
              onChange={(event) => setNewRefName(event.target.value)}
            ></Input>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              leftIcon={<AddIcon />}
              colorScheme="red"
              mr={3}
              onClick={creationRef}
              isLoading={isLoading}
              loadingText="client en cours de création"
              variant="outline"
            >
              Créer nouveau client
            </Button>
            <Button size="sm" colorScheme="c2i" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isModifyOpen} onClose={onModifyClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={modifyRefName}
              onChange={(event) => setModifyRefName(event.target.value)}
            ></Input>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              leftIcon={<AddIcon />}
              colorScheme="red"
              mr={3}
              onClick={modifyClient}
              isLoading={isLoading}
              loadingText="client en cours de modification"
              variant="outline"
            >
              Modifier client
            </Button>
            <Button size="sm" colorScheme="c2i" mr={3} onClick={onModifyClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {listRefIsLoading ? (
        <Spinner mt="30px" ml="50px" />
      ) : (
        <Table size="sm" mt="30px">
          <Thead>
            <Tr>
              <Th>Nom du client</Th>
              <Th>Date de création</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {refs.map((ref: Refs) => (
              <Tr key={ref.name}>
                <Td>{ref.name}</Td>
                <Td>{ref.date}</Td>
                <Td>
                  <Tooltip label="Modifier le client">
                    <IconButton
                      onClick={() => {
                        setIdToModify(ref.id)
                        onModifyOpen()
                        setModifyRefName(ref.name)
                      }}
                      icon={<EditIcon />}
                      aria-label="modify packaging"
                    />
                  </Tooltip>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  )
}
