import { useEffect, useState } from "react"
import { Button, Spinner } from "@chakra-ui/react"
import { config } from "../../Constants"
import { Table, Thead, Tbody, Tr, Th, Td, Input } from "@chakra-ui/react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import { useDisclosure } from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"
import axios from "axios"

type Refs = {
  name: string
  date: string
}

export default function Referentiels() {
  // Get list of referentiels
  const [refs, setRefs] = useState<Array<Refs>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [flagNewRef, setFlagNewRef] = useState<number>(0)
  const [listRefIsLoading, setListRefIsLoading] = useState<boolean>(false)
  const [newRefName, setNewRefName] = useState<String>("")
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    setListRefIsLoading(true)
    const url = config.url.API_URL
    axios
      .get(url + "/referentiels")
      .then((res) => {
        setRefs(res.data)
        setListRefIsLoading(false)
      })
      .catch((res) => {
        console.log(res)
        //setRefs(res.data)
      })
  }, [flagNewRef])

  const creationRef = async () => {
    if (newRefName === "") {
      alert("Veuillez saisir un nom de référentiel")
      return
    }
    const verif = refs.find((ref) => ref.name === newRefName)
    if (verif) {
      alert("Ce nom de référentiel existe déjà")
      return
    }
    if (newRefName === "") {
      alert("Veuillez saisir un nom de référentiel")
      return
    }
    setIsLoading(true)
    const url = config.url.API_URL

    await axios
      .post(url + "/referentiels/new", {
        name: newRefName,
      })
      .then(function (response: any) {
        console.log(response)
        setIsLoading(false)
        onClose()
        setFlagNewRef(1)
        return
      })
      .catch(function (error: any) {
        console.log(error)
        return
      })
    setIsLoading(false)
  }
  return (
    <>
      <Button onClick={onOpen} size="sm" colorScheme="c2i">
        Créer un référentiel
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nouveau référentiel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Nom"
              onChange={(event) => setNewRefName(event.target.value)}
            ></Input>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              leftIcon={<AddIcon />}
              colorScheme="red"
              mr={3}
              onClick={creationRef}
              isLoading={isLoading}
              loadingText="Référentiel en cours de création"
              variant="outline"
            >
              Créer nouveau référentiel
            </Button>
            <Button size="sm" colorScheme="c2i" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {listRefIsLoading ? (
        <Spinner mt="30px" ml="50px" />
      ) : (
        <Table size="sm" mt="30px">
          <Thead>
            <Tr>
              <Th>Nom du référentiel</Th>
              <Th>Date de création</Th>
            </Tr>
          </Thead>
          <Tbody>
            {refs.map((ref: Refs) => (
              <Tr key={ref.name}>
                <Td>{ref.name}</Td>
                <Td>{ref.date}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  )
}
