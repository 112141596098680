import { useState } from "react"
import { Box, VStack } from "@chakra-ui/react"
import * as Yup from "yup"
import {
  CheckboxContainer,
  CheckboxControl,
  InputControl,
  SubmitButton,
  SwitchControl,
} from "formik-chakra-ui"
import { Formik } from "formik"

import { config } from "Constants"
import axios from "axios"
import { UsersType } from "./Users"
import { Client } from "components/utils"

type Props = {
  onClose: () => void
  setFlagNewUser: (flag: any) => void
  idToModify: string | undefined
  listUsers: Array<UsersType>
  setIdToModify: (id: string | undefined) => void
  clients: Array<Client>
}

export default function UserForm({
  onClose,
  setFlagNewUser,
  idToModify,
  listUsers,
  setIdToModify,
  clients,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    login: Yup.string().required(),
    password: Yup.string().required(),
    is_admin: Yup.boolean(),
    is_full_access: Yup.boolean(),
    access: Yup.array(),
  })
  const initialValues = listUsers.find((user) => user._id === idToModify)

  return (
    <Formik
      initialValues={
        initialValues === undefined
          ? {
              name: "",
              login: "",
              password: "",
              is_admin: false,
              is_full_access: false,
              access: [],
            }
          : initialValues
      }
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log(values)
        setIsLoading(true)
        const url = config.url.API_URL
        if (idToModify === undefined) {
          axios
            .post(url + "/users/new", values)
            .then(function (response: any) {
              console.log(response)
              setFlagNewUser((prevValue: any) => prevValue + 1)
              setIsLoading(false)
              onClose()
              return
            })
            .catch(function (error: any) {
              console.log(error)
              return
            })
        } else {
          axios
            .put(url + "/users/" + idToModify, values)
            .then(function (response: any) {
              console.log(response)
              setFlagNewUser((prevValue: any) => prevValue + 1)
              setIsLoading(false)
              onClose()
              return
            })
            .catch(function (error: any) {
              console.log(error)
              return
            })
          setIdToModify(undefined)
          onClose()
          return
        }
      }}
    >
      {({ handleSubmit, values, errors }) => (
        <Box
          borderWidth="1px"
          rounded="lg"
          shadow="1px 1px 3px rgba(0,0,0,0.3)"
          maxWidth={500}
          p={6}
          m="10px auto"
          as="form"
          onSubmit={handleSubmit as any}
        >
          <VStack spacing="10px" align="left">
            <InputControl colorScheme="c2i" size="sm" name="name" label="Nom" />
            <InputControl
              colorScheme="c2i"
              size="sm"
              name="login"
              label="Login"
            />
            <InputControl
              colorScheme="c2i"
              size="sm"
              name="password"
              label="Password"
            />
            <SwitchControl
              colorScheme="c2i"
              size="sm"
              name="is_admin"
              label="Administrateur"
            />
            <SwitchControl
              colorScheme="c2i"
              size="sm"
              name="is_full_access"
              label="Accès à tous les clients"
            />
            <CheckboxContainer
              name="Clients"
              label="Clients accessibles par l'utilisateur"
            >
              {clients.map((client) => (
                <CheckboxControl
                  colorScheme="c2i"
                  key={client.name}
                  name="access"
                  value={client.id}
                >
                  {client.name}
                </CheckboxControl>
              ))}
            </CheckboxContainer>
            <SubmitButton isLoading={isLoading} colorScheme="c2i" size="sm">
              Enregister
            </SubmitButton>
          </VStack>
        </Box>
      )}
    </Formik>
  )
}
