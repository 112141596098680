import { EmballageWithBenchmark } from "./utils"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
} from "@chakra-ui/react"
import ScoringDetail from "./ScoringDetail"
import { Client } from "components/utils"
import { FournisseursType } from "components/Admin/Fournisseurs"
interface IProps {
  emballageWithBenchmark: EmballageWithBenchmark | undefined
  onDetailOpen: () => void
  onDetailClose: () => void
  isDetailOpen: boolean
  clients: Array<Client> | undefined
  listFournisseurs: Array<FournisseursType> | undefined
}

export default function ScoringModal({
  emballageWithBenchmark,
  isDetailOpen,
  onDetailClose,
  clients,
  listFournisseurs,
}: IProps) {
  return (
    <Modal isOpen={isDetailOpen} onClose={onDetailClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <Button
          size="sm"
          ml="20px"
          mt="15px"
          w="70px"
          colorScheme="c2i"
          mr={3}
          onClick={onDetailClose}
        >
          Fermer
        </Button>
        <ModalHeader>Détail du scoring de l'emballage</ModalHeader>

        <ModalBody>
          <ScoringDetail
            emballageWithBenchmark={emballageWithBenchmark}
            clients={clients}
            listFournisseurs={listFournisseurs}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
