import React from "react"
import { VStack, Text, Input } from "@chakra-ui/react"

interface IProps {
  name: string
  handleChange: any
  value: string
  label: string
}

export default function CustomInput(props: IProps) {
  //const path = "/emballages/list"

  return (
    <VStack align="left">
      <Text>{props.label}</Text>
      <Input
        bg="white"
        size="xs"
        id={props.name}
        name={props.name}
        type="text"
        onChange={props.handleChange}
        value={props.value}
      />
    </VStack>
  )
}
