import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import {
  Box,
  Button,
  Input,
  Text,
  Select,
  SimpleGrid,
  HStack,
  Checkbox,
} from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import { SubmitButton } from "formik-chakra-ui"
import { config } from "Constants"
import { InitialValues } from "./utils"
import axios from "axios"
import EmballageForm from "./EmballageForm"
import { Alert, AlertTitle } from "@chakra-ui/react"
import { getItem } from "../../services/LocalStorage"

export default function AddEdit() {
  const [initialValues, setInitialValues] = useState<InitialValues | undefined>(
    undefined
  )
  const [isReferentielLoaded, setIsReferentielLoaded] = useState(false)
  const [referentielId, setReferentielId] = useState<string>("")
  const [referentiel, setReferentiel] = useState<any>(undefined)
  const [clientsList, setClientsList] = useState<any>(undefined)
  const [fournisseursList, setFournisseursList] = useState<any>(undefined)
  const { emballageId } = useParams()
  const navigate = useNavigate()
  const token = getItem("token")
  const isAddMode = !emballageId
  const url = config.url.API_URL
  useEffect(() => {
    // Get all clients
    axios
      .get(`${url}/fournisseurs`)
      .then((res) => {
        setFournisseursList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    // Get all clients
    axios
      .get(`${url}/clients/${token}`)
      .then((res) => {
        setClientsList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    if (isReferentielLoaded === false) {
      // New packaging - Get most recent ref id
      if (isAddMode) {
        axios
          .get(`${url}/referentiels/most_recent`)
          .then((res) => {
            setReferentielId(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
        if (referentielId !== "") {
          axios
            .get(`${url}/referentiels/${referentielId}`)
            .then((res) => {
              setReferentiel(res.data)
            })
            .catch((err) => {
              console.log(err)
            })
          setIsReferentielLoaded(true)
        }
      } else {
        // edit package - retrieve ref id from emballage
        axios
          .get(`${url}/emballages/${emballageId}`)
          .then((res) => {
            setInitialValues(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
        if (initialValues !== undefined) {
          setReferentielId(initialValues.referentiel_id)
        }
        if (referentielId !== "") {
          axios
            .get(`${url}/referentiels/${referentielId}`)
            .then((res) => {
              setReferentiel(res.data)
            })
            .catch((err) => {
              console.log(err)
            })
          setIsReferentielLoaded(true)
        }
      }
    }
  }, [isReferentielLoaded, referentielId, initialValues])

  useEffect(() => {
    if (isAddMode) {
      const new_emballage = {
        name: "",
        type: "",
        contenance: "",
        ratio_vente_france: 50,
        ratio_vente_europe: 50,
        corps: {
          fournisseur_id: "",
          elements: undefined,
          specs: {
            additifs: "",
            coloration: "",
            is_marquage: false,
            marquage_type: "",
            is_etiquette: false,
            etiquette_matiere: "",
            etiquette_adhesif: "",
          },
        },
        tete: {
          fournisseur_id: "",
          is_detachable: false,
          elements: undefined,
        },
        bouchon: {
          fournisseur_id: "",
          is_detachable: false,
          elements: undefined,
          specs: {
            additifs: "",
            coloration: "",
            is_marquage: false,
            marquage_type: "",
            is_etiquette: false,
            etiquette_matiere: "",
            etiquette_adhesif: "",
          },
        },
        transports: undefined,
      }

      setInitialValues({
        _id: undefined,
        referentiel_id: referentielId,
        is_benchmark_active: false,
        quantity: "",
        client_id: "",
        name: "",
        emballage: new_emballage,
        benchmark: JSON.parse(JSON.stringify(new_emballage)),
      })
    }
  }, [isAddMode, referentielId])

  function duplicate(setValues: any) {
    setValues((prev: InitialValues) => {
      const new_prev = { ...prev }
      new_prev.benchmark = JSON.parse(JSON.stringify(prev.emballage))
      return new_prev
    })
  }
  const addEmballage = (values: InitialValues, actions: any) => {
    console.log("addEmballage")
    axios
      .post(url + "/emballages/new", values)
      .then(function (response: any) {
        console.log(response)
        console.log("emballage saved")
        navigate("/emballages")
        return
      })
      .catch(function (error: any) {
        const info = error?.response?.data?.detail
        alert("L'enregistrement a échoué. " + info)
        actions.setSubmitting(false)

        console.log(error.detail)
        return
      })
  }
  const editEmballage = (values: any, actions: any) => {
    console.log("editEmballage")
    console.log(values)
    axios
      .put(url + "/emballages/" + emballageId, values)
      .then(function (response: any) {
        console.log(response)
        console.log("emballage saved")
        navigate("/emballages")
        return
      })
      .catch(function (error: any) {
        alert("L'enregistrement a échoué")
        actions.setSubmitting(false)
        console.log(error)
        return
      })
  }
  function onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const validationSchema = Yup.object().shape({
    quantity: Yup.number()
      .required("La quuantité est obligatoire")
      .typeError("La quantité saisie n'est pas valable"),
    name: Yup.string().required("Le nom de l'emballage est obligatoire"),
    client_id: Yup.string().required("Le client est obligatoire"),
    // .required("La quantité est obligatoire"),
    emballage: Yup.object().shape({
      contenance: Yup.number().typeError(
        "La contenance saisie n'est pas valable"
      ),
      ratio_vente_france: Yup.number()
        .min(0)
        .max(100)
        .typeError("Le ratio n'est pas valable"),
    }),
  })
  return (
    <>
      {initialValues !== undefined && isReferentielLoaded && (
        <Formik
          validationSchema={validationSchema}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            if (isAddMode) {
              console.log(values)
              addEmballage(values, actions)
            } else {
              editEmballage(values, actions)
            }
          }}
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            setValues,
            handleChange,
            handleBlur,
          }) => {
            return (
              <Box
                as="form"
                ml="30px"
                mt="40px"
                w="700px"
                onSubmit={handleSubmit as any}
                onKeyDown={onKeyDown}
              >
                <SimpleGrid columns={2} spacing={10} mt={10}>
                  <Box>
                    <Text>Référentiel</Text>
                    {isAddMode ? (
                      <Input
                        w="200px"
                        size="sm"
                        isDisabled
                        value={
                          referentiel !== undefined ? referentiel.name : ""
                        }
                      />
                    ) : (
                      <Input
                        w="200px"
                        size="sm"
                        isDisabled
                        value={
                          referentiel !== undefined ? referentiel.name : ""
                        }
                      />
                    )}

                    <Text>Client*</Text>
                    <Select
                      w="200px"
                      size="sm"
                      onChange={handleChange}
                      value={values.client_id}
                      name="client_id"
                      placeholder="Choisir un client"
                    >
                      {clientsList !== undefined &&
                        clientsList.map((client: any, index: any) => (
                          <option key={index} value={client.id}>
                            {client.name}
                          </option>
                        ))}
                    </Select>
                    {errors.client_id ? (
                      <Alert status="warning" height="10px" fontSize={12}>
                        <AlertTitle>{errors.client_id}</AlertTitle>
                      </Alert>
                    ) : null}
                  </Box>
                  <Box>
                    <Text>Nom*</Text>
                    <Input
                      w="200px"
                      bg="white"
                      size="sm"
                      id="name"
                      name="name"
                      type="text"
                      onChange={handleChange}
                      value={values.name}
                    />
                    {errors.name ? (
                      <Alert status="warning" height="10px" fontSize={12}>
                        <AlertTitle>{errors.name}</AlertTitle>
                      </Alert>
                    ) : null}
                    <Text>Quantité*</Text>

                    <Input
                      w="200px"
                      size="sm"
                      id="quantity"
                      name="quantity"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.quantity}
                    />

                    {errors.quantity ? (
                      <Alert status="warning" height="10px" fontSize={12}>
                        <AlertTitle>{errors.quantity}</AlertTitle>
                      </Alert>
                    ) : null}
                  </Box>
                </SimpleGrid>
                <EmballageForm
                  fournisseursList={fournisseursList}
                  errors={errors}
                  touched={touched}
                  referentiel={referentiel}
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  base={"emballage"}
                />
                <Checkbox
                  colorScheme="c2i"
                  onChange={(e) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      is_benchmark_active: e.target.checked,
                    }))
                  }}
                  mt="10px"
                  isChecked={values.is_benchmark_active}
                >
                  Ajouter un benchmark
                </Checkbox>
                {values.is_benchmark_active && (
                  <Box mt="15px">
                    <Button
                      colorScheme="c2i"
                      size="sm"
                      onClick={() => duplicate(setValues)}
                      mt="20px"
                    >
                      Dupliquer les données de l'emballage sur le benchmark
                    </Button>
                    <EmballageForm
                      fournisseursList={fournisseursList}
                      errors={errors}
                      touched={touched}
                      referentiel={referentiel}
                      values={values}
                      setValues={setValues}
                      handleChange={handleChange}
                      base={"benchmark"}
                    />
                  </Box>
                )}

                <HStack mt="20px" spacing={10} mb="20px">
                  <Button
                    colorScheme="c2i"
                    size="sm"
                    onClick={() => {
                      navigate("/emballages")
                    }}
                  >
                    Annuler
                  </Button>
                  <SubmitButton colorScheme="c2i" size="sm">
                    Enregister
                  </SubmitButton>
                </HStack>
              </Box>
            )
          }}
        </Formik>
      )}
    </>
  )
}
