import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Flex,
  Center,
  Spinner,
  Heading,
} from "@chakra-ui/react"
import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Auth } from "../contexts/Auth"
import { config } from "../Constants"
import { addItem } from "../services/LocalStorage"
import {hasAuthentificated} from "../services/AuthApi";

export default function Login() {
  const [user, setUser] = useState({ login: "", password: "" })
  const [showAccessDenied, setShowAccessDenied] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { isAuthentificated, setIsAuthentificated } = useContext(Auth)
  const navigate = useNavigate()
  const handleChange = ({ currentTarget }: any) => {
    const { id, value } = currentTarget
    setUser({ ...user, [id]: value })
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setIsLoading(true)
    setShowAccessDenied(false)

    try {
      const url = config.url.API_URL
      const response = await fetch(url + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      })
      const body = await response.json()
      if (response.status === 401) {
        setShowAccessDenied(true)
        return
      }
      if (response.status === 200) {
        addItem("token", body.token)
        setIsAuthentificated(hasAuthentificated())
        navigate("/")
        return
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isAuthentificated) {
      navigate("/")
    }
  }, [isAuthentificated, navigate])
  return (
    <Center>
      <Box maxW="sm" borderWidth="1px" borderRadius="lg" mt="100px">
        <Heading size="md">C2i application de scoring</Heading>
        <FormControl onSubmit={handleSubmit} p="20px">
          <FormLabel>Login</FormLabel>
          <Input id="login" onChange={handleChange} />
          <FormLabel>Mot de passe</FormLabel>
          <Input id="password" type="password" onChange={handleChange} />
          {showAccessDenied && <Box>Accès refusé</Box>}
          <Button mt={4} colorScheme="c2i" type="submit" onClick={handleSubmit}>
            {isLoading ? <Spinner /> : <Box>Se connecter</Box>}
          </Button>
        </FormControl>
      </Box>
    </Center>
  )
}
