import {
  HStack,
  Select,
  VStack,
  Text,
  Button,
  useDisclosure,
} from "@chakra-ui/react"
import { SwitchControl } from "formik-chakra-ui"
import TableElements from "./TableElements"
import ModalNewElement from "./ModalNewElement"
import { E } from "./utils"

interface IProps {
  handleChange: any
  values: any
  base: any
  fournisseursList: any
  choixAdditif: any
  choixCouleur: any
  choixEncre: any
  choix_matiere: any
  choixAdhesif: any
  buttonElementProps: any
  setValues: any
  referentiel: any
  part: E
}

export default function CorpsBouchonForm({
  handleChange,
  values,
  base,
  fournisseursList,
  choixAdditif,
  choixCouleur,
  choixEncre,
  choix_matiere,
  choixAdhesif,
  buttonElementProps,
  setValues,
  referentiel,
  part,
}: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <VStack spacing="30px" align="left">
      <Button {...buttonElementProps} onClick={onOpen} colorScheme="c2i">
        Nouvel élement
      </Button>
      <ModalNewElement
        onClose={onClose}
        isOpen={isOpen}
        referentiel={referentiel}
        setValues={setValues}
        values={values}
        base={base}
        famille_element={part}
      />

      <TableElements
        setValues={setValues}
        values={values}
        famille_element={part}
        base={base}
      />
      {part === "bouchon" && (
        <SwitchControl
          name={base + "." + part + ".is_detachable"}
          label="La fermeture: bouchon / pompe est-elle facilement détachable du corps ?"
        />
      )}
      <HStack>
        <VStack align="left">
          <Text>Fournisseur*</Text>
          <Select
            size="sm"
            onChange={handleChange}
            value={values[base][part].fournisseur_id}
            name={base + "." + part + ".fournisseur_id"}
            placeholder="Choisir un fournisseur"
            bg="white"
          >
            {fournisseursList !== undefined &&
              fournisseursList.map((x: any, index: any) => (
                <option value={x._id} key={index}>
                  {x.name}, pays: {x.pays}
                </option>
              ))}
          </Select>
        </VStack>
        <VStack align="left">
          <Text>Additifs</Text>
          <Select
            size="sm"
            onChange={handleChange}
            value={values[base][part].specs.additifs}
            name={base + "." + part + ".specs.additifs"}
            placeholder="Choisir un additif"
            bg="white"
          >
            {choixAdditif !== undefined &&
              choixAdditif.map((x: any, index: any) => (
                <option value={x} key={index}>
                  {x}
                </option>
              ))}
          </Select>
        </VStack>

        <VStack align="left">
          <Text>Coloration</Text>
          <Select
            size="sm"
            onChange={handleChange}
            value={values[base][part].specs.coloration}
            name={base + "." + part + ".specs.coloration"}
            placeholder="Choisir une coloration"
            bg="white"
          >
            {choixCouleur !== undefined &&
              choixCouleur.map((x: any, index: any) => (
                <option value={x} key={index}>
                  {x}
                </option>
              ))}
          </Select>
        </VStack>
      </HStack>
      <VStack align="left">
        <SwitchControl
          name={base + "." + part + ".specs.is_marquage"}
          label="Marquage ?"
        />
        {values[base][part].specs.is_marquage === true && (
          <Select
            size="sm"
            onChange={handleChange}
            value={values[base][part].specs.marquage_type}
            name={base + "." + part + ".specs.marquage_type"}
            placeholder="Choisir un marquage"
            bg="white"
          >
            {choixEncre !== undefined &&
              choixEncre.map((x: any, index: any) => (
                <option value={x.Type} key={index}>
                  {x.Matériau} - {x.Type}
                </option>
              ))}
          </Select>
        )}
      </VStack>
      <VStack align="left">
        <SwitchControl
          name={base + "." + part + ".specs.is_etiquette"}
          label="Etiquette ?"
        />

        {values[base][part].specs.is_etiquette === true && (
          <>
            <Select
              size="sm"
              onChange={handleChange}
              value={values[base][part].specs.etiquette_matiere}
              name={base + "." + part + ".specs.etiquette_matiere"}
              placeholder="Choisir une matière"
              bg="white"
            >
              {choix_matiere !== undefined &&
                choix_matiere.map((x: any, index: any) => (
                  <option value={x} key={index}>
                    {x}
                  </option>
                ))}
            </Select>
            {["PE, PP, OPP", "Papier", "Autres"].includes(
              values[base][part].specs.etiquette_matiere
            ) && (
              <Select
                size="sm"
                onChange={handleChange}
                value={values[base][part].specs.etiquette_adhesif}
                name={base + "." + part + ".specs.etiquette_adhesif"}
                placeholder="Choisir un adhésif"
                bg="white"
              >
                {choixAdhesif !== undefined &&
                  choixAdhesif.map((x: any, index: any) => (
                    <option value={x.Type} key={index}>
                      {x.Type}
                    </option>
                  ))}
              </Select>
            )}
          </>
        )}
      </VStack>
    </VStack>
  )
}
