import { useState } from "react"
import { Box, VStack } from "@chakra-ui/react"
import * as Yup from "yup"
import { InputControl, SubmitButton, SelectControl } from "formik-chakra-ui"
import { Formik } from "formik"

import { config } from "Constants"
import axios from "axios"
import { FournisseursType } from "./Fournisseurs"
type Props = {
  onClose: () => void
  setFlagNewFournisseur: (flag: any) => void
  idToModify: string | undefined
  listFournisseurs: Array<FournisseursType>
  setIdToModify: (id: string | undefined) => void
}

export default function FournisseurForm({
  onClose,
  setFlagNewFournisseur,
  idToModify,
  listFournisseurs,
  setIdToModify,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    factory_elec_mix: Yup.number().required(),
    energy_consumption: Yup.number().required(),
    annual_production: Yup.number().required(),
  })

  const initialValues = listFournisseurs.find(
    (fournisseur) => fournisseur._id === idToModify
  )

  return (
    <Formik
      initialValues={
        initialValues === undefined
          ? {
              name: "",
              pays: "France",
              factory_elec_mix: 0,
              energy_consumption: 0,
              annual_production: 0,
            }
          : initialValues
      }
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log(values)
        setIsLoading(true)
        const url = config.url.API_URL
        if (idToModify === undefined) {
          axios
            .post(url + "/fournisseurs/new", values)
            .then(function (response: any) {
              console.log(response)
              setFlagNewFournisseur((prevValue: any) => prevValue + 1)
              setIsLoading(false)
              onClose()
              return
            })
            .catch(function (error: any) {
              console.log(error)
              return
            })
        } else {
          axios
            .put(url + "/fournisseurs/" + idToModify, values)
            .then(function (response: any) {
              console.log(response)
              setFlagNewFournisseur((prevValue: any) => prevValue + 1)
              setIsLoading(false)
              onClose()
              return
            })
            .catch(function (error: any) {
              console.log(error)
              return
            })
          setIdToModify(undefined)
          onClose()
          return
        }
      }}
    >
      {({ handleSubmit, values, errors }) => (
        <Box
          borderWidth="1px"
          rounded="lg"
          shadow="1px 1px 3px rgba(0,0,0,0.3)"
          maxWidth={500}
          p={6}
          m="10px auto"
          as="form"
          onSubmit={handleSubmit as any}
        >
          <VStack spacing="10px" align="left">
            <InputControl size="sm" name="name" label="Nom" />
            <SelectControl size="sm" name="pays" label="Pays">
              <option value="France">France</option>
              <option value="Europe">Europe</option>
              <option value="Chine">Chine</option>
            </SelectControl>
            <InputControl
              size="sm"
              name="factory_elec_mix"
              label="Mix électrique de l'usine (kg CO2eq/kWh)"
            />
            <InputControl
              size="sm"
              name="energy_consumption"
              label="Consommation énergétique (MWh)"
            />
            <InputControl
              size="sm"
              name="annual_production"
              label="Production annuelle (t)"
            />

            <SubmitButton isLoading={isLoading} colorScheme="c2i" size="sm">
              Enregister
            </SubmitButton>
          </VStack>
        </Box>
      )}
    </Formik>
  )
}
