import React, { useContext, useEffect, useState } from "react"
import { HStack, Link } from "@chakra-ui/react"
import { Box, VStack, Flex, Button } from "@chakra-ui/react"
import { Auth } from "../contexts/Auth"
import { getItem } from "../services/LocalStorage"
import jwt_decode from "jwt-decode"
import { Icon } from "@chakra-ui/react"
import { MdBuild, MdSettings, MdViewList } from "react-icons/md"
import { Payload } from "./utils"

export default function Navbar() {
  const { isAuthentificated, setIsAuthentificated } = useContext(Auth)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    const token = getItem("token")
    if (token === null) {
      return
    }
    //payload jwt token
    const payload: Payload = jwt_decode(token)
    setIsAdmin(payload.isAdmin)
  }, [isAuthentificated])
  return (
    <Flex h="50px" w="100%" justify="left" pr="50px">
      <VStack pt="30px" color="white" spacing="25px" align="left" pl="20px">
        {isAuthentificated && (
          <>
            <Link href="/emballages">
              <HStack spacing="10px">
                <Icon w={6} h={6} as={MdViewList} />
                <Box>Emballages</Box>
              </HStack>
            </Link>
            {/* <Link href="/creation_emballage">
              <HStack spacing="10px">
                <Icon w={6} h={6} as={MdBuild} />
                <Box>Créer un emballage</Box>
              </HStack>
            </Link> */}
            {isAdmin && (
              <Link href="/admin">
                <HStack spacing="10px">
                  <Icon w={6} h={6} as={MdSettings} />
                  <Box>Admin</Box>
                </HStack>
              </Link>
            )}
          </>
        )}
      </VStack>
    </Flex>
  )
}
