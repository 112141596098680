import React from "react"
import {Box, HStack, Flex, Button, VStack} from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import Referentiels from "./Admin/Referentiels"
import Clients from "./Admin/Clients"
import Users from "./Admin/Users"
import Fournisseurs from "./Admin/Fournisseurs"
import NewDemoAccount from "./Admin/NewDemoAccount";
export default function Admin() {
  return (

    <Tabs>
      <TabList>
        <Tab>Utilisateurs</Tab>
        <Tab>Clients</Tab>
        <Tab>Fournisseurs</Tab>
        <Tab>Référentiels</Tab>
        <Tab>Compte de démo</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Users />
        </TabPanel>
        <TabPanel>
          <Clients />
        </TabPanel>
        <TabPanel>
          <Fournisseurs />
        </TabPanel>
        <TabPanel>
          <Referentiels />
        </TabPanel>
        <TabPanel>
          <NewDemoAccount />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
